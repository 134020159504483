import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ProductIndicators } from './TierIcon';
import { Segment, Loader, Popup, Button } from 'semantic-ui-react';
import warning from '../images/warning.png';
import '../Products/ProductDetails_style.css';
import restUtils from '../../utils/restUtils';
import { addToFavorites, removeFromFavorites, getFavoriteItems } from '../Favorites/actions';
import ImageGallery from './ImageGallery';
import history, { Event, ProductData } from '../../history';
import VendorDetailsPopup from '../Vendors/component/vendor-details-popup';
import {
  addProduct,
  createNAddCart,
  chooseCart,
  addReunionCartProduct,
  addMonthlySpecialCart
} from '../Cart/actions';
import { connect } from 'react-redux';
import './ProductDetails.css';  
import { bindActionCreators } from 'redux';
import BreadcrumbComp from '../../components/BreadcrumbComp';
import renderImages from '../common/RenderImages';
import tv_logo from '../images/Logo.svg.png';
import InternalServerError from '../../views/ErrorPages/500_error_page';
import Dialog from 'react-dialog';
import CompetitorPricing from '../common/CompetitorPricing';
import { MaskPrice } from './MaskPrice';
import { visibilityIndicators, nonStockMessage  } from './constants';
import { TierTable, TierTableNew, ShowPromoCostExpDetails } from './TierTable';
import  PlanogramsTable  from './PlanogramsTable';
import utilities, {shouldHideCostForPreference,shouldHideSUGG_RETAILForPreference} from '../../utils/utilities';
import { CUSTOMER_VIEW, MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import classnames from 'classnames';
import MultistoreToCartModal from '../Cart/MultistoreToCartModal';
import { getMultipleImages } from './imageUtils';
import { PRODUCT_DETAILS, VARIABLEPRICING, ORDER_HISTORY_PDP, INVOICE_ORDER_HISTORY, MAP_REDIRECTION_URL, MONTHLY_SPECIALS_PDP, VENDOR_POLICY_MAP_REDIRECTION_URL, ALL_WAREHOUSE_DETAILS, getImageURl, DEALER_PROGRAMS } from '../../../src/urlConstants';
import { validateProductQuantity, isRestrictedQuantity, addToCart, addMonthlySpecialToCartpdp } from './productHandler';
import AddToCartModal from '../Cart/AddToCartModal';
import ReactToPrint from 'react-to-print';
import BusinessRules from './BusinessRules';
import {focusChange} from '../../../src/components/NavBar/actions';
import { getImageLink } from '../ReunionEvent/AllReunion/itemListingUtilities';
import _ from 'lodash';
import Freight from '../Freight';
import { Modal } from 'semantic-ui-react';

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    const showDetailedSpec =
      this.props.getprefer &&
        this.props.getprefer.productSpecView &&
        this.props.getprefer.productSpecView === 'C'
        ? false
        : true;
    this.state = {
      product: {},
      MSData: {},
      loading: false,
      quantity: '',
      quantity1: '',
      quantity2: '',
      quantity3: '',
      quantity4: '',
      overrideRetail: '',
      isMultistoreModalOpen:false,
      orderHistory: [],
      invoicedOrders:[],
      showDetailedSpec: showDetailedSpec,
      onlineRestriction: showDetailedSpec,
      showShipping: showDetailedSpec,
      showInvoicedOrders:showDetailedSpec,
      showorderHistory: showDetailedSpec,
      showPlanograms: showDetailedSpec,
      showStoreInformation: showDetailedSpec,
      showOnHandInventory: showDetailedSpec,
      posSale: showDetailedSpec,
      onlineRestrictionDialog: false,
      serverError: false,
      isFavorite: false,
      competitors: false,
      imageSrcs: [],
      productSelectedForModal: null,
      indexForModal: 0,
      isAddToCartModalOpen: false,
      variableRetail: '',
      variableMethod: '',
      isDiscontinuedHideAddToCart: false,
      showSpec: showDetailedSpec,
      showWholesale: showDetailedSpec,
      showRestriction: showDetailedSpec,
      showRetail:showDetailedSpec,
      showOrderingSpec : showDetailedSpec,
      showProductDimensionSpec: showDetailedSpec,
      showPricing: showDetailedSpec,
      showWarrantySpec : showDetailedSpec,
      showScrollButton: false,
      showBullets : false,
      monthlySplNonStockItem: false,
      isMissing:this.props?.location && this.props?.location?.state?.isCtbAssortment &&this.props?.location?.state?.missing.toString() && this.props?.location?.state?.missing.toString() ? this.props?.location?.state?.missing.toString(): null,
      isAltPDPapiProduct:true
    };
    this.addToCart = addToCart.bind(this);
    this.addMonthlySpecialToCartpdp = addMonthlySpecialToCartpdp.bind(this);
    this.refProdDim = React.createRef();
    this.refDetails = React.createRef();
    this.refPricing = React.createRef();
    this.refOrderingSpec = React.createRef();
    this.refShipping= React.createRef();
    this.refOnlineRest = React.createRef();
    this.refPlanograms = React.createRef();
    this.refRetailerQOH= React.createRef();
    this.refWarranty = React.createRef();
    this.refWarehouse = React.createRef();
    this.refStoreInfo = React.createRef();
    this.refTitleLinks = React.createRef();
    this.refSpec = React.createRef();
    this.refWholesale = React.createRef();
    this.refRestriction = React.createRef();
    this.refRetail = React.createRef();
  }
  componentDidMount() {
    Event('PRODUCT', 'Viewed', this.props.match.params.productId.toString());
    window.addEventListener('scroll', this.handleScroll, true);
    this.cursorPlacementPrefernece();
    this.props.getFavoriteItems();
    const currentCartDetails = this.props.carts.filter((c) => c.cartId === this.props.currentCartId);
    let currentCart = this.props.currentCartId;
    if(currentCartDetails && currentCartDetails.length > 0 && currentCartDetails[0]?.cartInfoName && currentCartDetails[0].cartInfoName === 'MS'){
      const msCarts = this.props.carts.filter((c) => c.cartInfoName === 'MS');
      currentCart = msCarts.length > 0 ? msCarts[0]?.cartId : this.props.currentCartId;
    }
    this.cursorPlacementPrefernece();
    if (this.props.match.params.productId) {
      this.getProductDetails(
        this.props.match.params.productId,
        currentCart
      );
    }
  }

componentWillReceiveProps(newProps) {
    if (newProps.match.params.productId !== this.props.match.params.productId ||
      this.props.currentCartId !== newProps.currentCartId
    ) {
      this.getProductDetails(
        newProps.match.params.productId,
        newProps.currentCartId
      );
    }
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.handleScroll);
  }

  cursorPlacementPrefernece = () => {
    if(window.innerWidth >= 1024) {
      (this.props.getprefer && this.props.getprefer.cursorPlacement ==='S') &&
      document.getElementById('searchbox').focus();
  
      if(this.props.getprefer && this.props.getprefer.cursorPlacement ==='Q' && document.getElementById('pdpQuantity')){
        document.getElementById('pdpQuantity').focus();
      }  
    }
  }

  handleScroll = () => {
    if(this.refTitleLinks && this.refTitleLinks.current){
      const top = this.refTitleLinks.current.getBoundingClientRect().top;
      if(top <= 60){
        this.setState({
          showScrollButton : true
        })
      } else {
          this.setState({
            showScrollButton : false
          })
      }
    }
  }

  showProductBullets =() => {
    this.setState({showBullets : true});
  }

  hideProductBullets =() => {
    this.setState({showBullets : false});
  }

  goToSection = (section) => {
    var curRef = this.refProdDim.current;
    switch(section){
      case "showSpec":
        curRef = this.refSpec.current;
        this.setState({
          showProductDimensionSpec: true,
          showDetailedSpec: true,
          showWarrantySpec: true,
        })
        break;
      case "showWholesale":
        curRef = this.refWholesale.current;
        this.setState({
          showOrderingSpec: true,
          showShipping: true,
          showInvoicedOrders: true,
          showorderHistory: true
        })
        break;
      case "showRestriction":
        curRef = this.refRestriction.current;
        this.setState({
          onlineRestriction: true
        })
        break;
      case "showRetail":
        curRef = this.refRetail.current;
        this.setState({
          showPlanograms: true,
          showOnHandInventory: true,
          posSale: true
        })
        break;
      case "showStoreInformation":
          curRef = this.refStoreInfo.current;
          break;
      case "showTitleLinks":
          curRef = this.refTitleLinks.current;
          break;
      case "showProductDimensionSpec":
        curRef = this.refProdDim.current;
        break;
      case "showOrderingSpec":
        curRef = this.refOrderingSpec.current;
        break;
      case "showDetailedSpec":
        curRef =  this.refDetails.current;
        break;
      case "showWarrantySpec":
        curRef =  this.refWarranty.current;
        break;
      case "showPricing":
        curRef =   this.refPricing.current;
        break;
      case "onlineRestriction":
        curRef = this.refOnlineRest.current;
        break;
      case "showShipping":
        curRef = this.refShipping.current;
        break;
      case "showPlanograms":
        curRef = this.refPlanograms.current;
        break;
      case "showOnHandInventory":
        curRef = this.refRetailerQOH.current;
        break;
      case "posSale":
        curRef = this.refWarehouse.current;
        break;
      default:
        curRef = this.refTitleLinks.current;
        break;
    }

    if(curRef){
      this.setState({[section]: true},() => {
        var pos =  curRef.style.position;
        var top =  curRef.style.top;
        curRef.style.position = 'relative';
        if(window && window.innerWidth > 767){
          curRef.style.top = '-90px';
        }
        else{
          curRef.style.top = '-150px';
        }
        curRef.scrollIntoView({ behavior: 'smooth',block: 'start' });
        curRef.style.top = top;
        curRef.style.position = pos;
      });
    }  
  }

  isObjEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

toggleOrderCard = (index,isInvoicedOrder) => {
    const icon = document.getElementsByClassName('toggleIcon')[index];
    icon.className = icon.classList.contains('right')
      ? 'angle down icon toggleIcon'
      : 'angle right icon toggleIcon';
    const contentDiv = document.getElementById(`${isInvoicedOrder?'order-body':'invoice-body'}` + index);
    contentDiv.style.display = contentDiv.style.display === 'none' ? 'block' : 'none';
  };

  handleFieldChange = e => {
    const { value, name } = e.target;
    const inputValue = parseFloat(value);
    switch (name) {
      case 'overrideRetail':
        if (!/^(\d{0,4}\.\d{0,2}|\d{0,4})$/.test(value)) {
          return;
        }
        if (inputValue < 0) {
          utilities.showToast('Overite Retail should be positive number!', true);
          this.qtyCursorFocus();
          return;
        }
        break;
      case 'quantity1':
      case 'quantity2':
      case 'quantity3':
      case 'quantity4':
      case 'quantity':
        // a digit or an empty value
        if (!/^(\d+)$|^$/.test(value)) {
          return;
        }
        if (inputValue < 1) {
          utilities.showToast('Quantity should be positive number!', true);
          this.qtyCursorFocus();
          return;
        }
        if (value > 9999) {
          utilities.showToast(`Quantity should not exceed 9999!`, true);
          this.qtyCursorFocus();
          return;
        }
        break;
      default:
    }
    this.setState({ [name]: value });
  };

  toggleFavorite = () => {
    this.state.isFavorite
      ? this.props.removeFromFavorites([this.state.product.Item_Number])
      : this.props.addToFavorites(this.state.product.Item_Number);
    this.setState(prevState => {
      return { isFavorite: !prevState.isFavorite };
    });
  };

  searchModeUpdate = () => {
    document.getElementById('searchbox').focus();
  }

  multistoreHandleCarts= (product) => {
    this.setState({
      productSelectedForModal: product,
      isMultistoreModalOpen: true
    });
  };

  closeMultistoreModal=()=>{
    this.searchModeUpdate()
    this.setState({ isMultistoreModalOpen: false });
  }

  openCartModal = product => {
    this.setState({
      productSelectedForModal: product,
      isAddToCartModalOpen: true
    });
  };

  closeCartModal = () => {
    this.searchModeUpdate();
    this.setState({ isAddToCartModalOpen: false });
  };

  qtyCursorFocus = () => {
    if(document.getElementById('pdpQuantity')){
      document.getElementById('pdpQuantity').focus();
    }
  }

  handleAddToCart = () => {
    const { quantity, overrideRetail, product } = this.state;
    const { isReunionListing = false,monthlyRelayNbr,monthlyPromoNbr,isMonthlyListing} =
      this.props.location && this.props.location.state
        ? this.props.location.state
        : {};
    const CentralRDC = product.quantity && product.quantity.length >=1 ? product.quantity[0] : '';
    if(isMonthlyListing){
      this.addMonthlySpecialToCartpdp(
        product.Item_Number,
        monthlyRelayNbr,
        monthlyPromoNbr,
        product.Vendor_Id || product.vendorID,
        product.S_Pack_Type,
        product.S_Pack_Qty,
        quantity,
        overrideRetail
      );
      return;
    }
    if (!isReunionListing && !validateProductQuantity(
            product.S_Pack_Type,
            product.S_Pack_Qty,
            quantity, product.maxQty)) {
              this.qtyCursorFocus();
              return;
    }
    if (!isReunionListing && isRestrictedQuantity(quantity)) {
      utilities.showToast(`Quantity should not be ${quantity}!`, true);
      this.qtyCursorFocus();
      return;
    }
    if (product.rdcDoesNotStockItem) {
      utilities.showToast(nonStockMessage, true);
      this.qtyCursorFocus();
      return;
    }
    const cartId = this.props.currentCartId;
    
    if (this.props.currentCartType !== 'REGULAR' && this.props.whCarts.length > 0) {
      this.openCartModal(product);
    }  else if (!cartId || this.props.whCarts.length===0) {
      this.addToCart(
        product.Item_Number,
        product.pricing.natCost, // Member cost is natcost
        quantity,
        overrideRetail,
        product.S_Pack_Type,
        product.S_Pack_Qty,
        'new',
        this.props.rdc,
        this.props.storeId
      );
    } else {
      this.addToCart(
        product.Item_Number,
        product.pricing.natCost, // Member cost is natcost
        quantity,
        overrideRetail,
        product.S_Pack_Type,
        product.S_Pack_Qty,
        cartId,
        this.props.rdc,
        this.props.storeId        
      );
    }
    this.searchModeUpdate();
    this.props.focusChange();
  };
  handleKeyDown = (e,product)=>{
    const msCheck = utilities.msExist(this.props.multiStoreDetails);
    if(this.props.multiStoreDetails.access !== 1 && e.key === 'Enter' && !this.props.isReunionOnlyStore){
        if(msCheck && msCheck > 1){
            this.multistoreHandleCarts(product);
        }
        else{
          this.handleAddToCart();
        }
      }
  }

  getProductDetails = (itemId, currentCartId) => {
    let isAltPDPapi = '';
    if(this.props?.location?.state){
      const {flowType, isMonthlyListing} = this.props.location.state;
      isAltPDPapi = (isMonthlyListing || flowType && (flowType==="1" || flowType==="2"));
    }
    const available = this.props?.location?.state?.available;
    const shoppingAreaMetaId = this.props?.location?.state?.shoppingAreaMetaId;
    const timestamppdp = Date.now(); 
    let url =
      PRODUCT_DETAILS + itemId +
      '&storeId=' + this.props.storeId +
      '&rdc=' + this.props.rdc +
      '&grpCollectionId=' + this.props.groupCollectionId +
      '&cartTimestamp='+timestamppdp+
      '&aliasSKURetailerGrpID='+this.props.aliasSKURetailerGrpID;
    if (!utilities.isEmptyOrNullString(currentCartId)) {
      url = url + '&cartid=' + currentCartId;
    }
    this.setState({ loading: true });
    let imageSrcs = [
      `https://images.truevalue.com/getimage.asp?id=${itemId}&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2`
    ];
    let product = null;
    let isFavorite = false;
    const promise1 = getMultipleImages(itemId).then(imgs => {
      imageSrcs = imgs[imgs.length - 1];
    });
    const promise2 = restUtils
      .getData(url)
      .then(response => {
        product = response.data;
        if(shoppingAreaMetaId === "7"){
          product = {...product, Available : available}
        }
        isFavorite =
          this.props.favorites.indexOf(parseInt(product.Item_Number)) > -1;
        if(!product.Item_Number && isAltPDPapi){
          this.setState({isAltPDPapiProduct:!isAltPDPapi})
          this.monthlySpecialPDP(this.props.storeId, this.props.rdc, currentCartId, itemId);          
        }
        if(product.whStockStatus === 'DIS'){
          this.setState({isDiscontinuedHideAddToCart: true })
        } else {
          this.setState({isDiscontinuedHideAddToCart: false })
        } 
        
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false, serverError: true });
      });

    let orderHistory = [];
    let invoicedOrders=[];
    let requestPayLoad = {
      storeId: this.props.storeId,
      userId: this.props.userId,
      itemNumber:itemId,
      limit: '4'
    };
    const promise3 = restUtils
      .postData(ORDER_HISTORY_PDP, requestPayLoad)
      .then(response => {
        orderHistory = response.data.orderList;
        invoicedOrders = response.data.orderList;
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false, orderHistory: [] });
      });
    let variableRetail = '';
    let variableMethod = '';
    let promise4 = '';
  	if(this.props.onVariablePricing){
    promise4 = restUtils
      .postData(VARIABLEPRICING, [{
        "memberNumber": this.props.storeId,
        "itemNumber": itemId
      }])
      .then(response => {
        if (!response.data || response.data.length < 1) return;
        variableRetail = utilities.emptyStringForZero(parseFloat(utilities.zeroForNull(
                              response.data[0].VariablePrice)).toFixed(2));
        variableMethod = utilities.emptyForNull(response.data[0].VariableMethod);
      })
    }
      const promise5 = promise2.then(res => {
        if(product.whStockStatus === 'DIS' && parseInt(product.Available) === 0) {
          return restUtils.getData(ALL_WAREHOUSE_DETAILS+
            product.Item_Number +
            '&rdc=' + 
            this.props.rdc).then(response => {
            let products = response.data.wareHouseList;
            let avaliableQty = products && products.some(ele => parseInt(ele.onHandInvtQty) > 0)
            // this.setState({isDiscontinuedHideAddToCart: !avaliableQty})
           })
        } else {
          // this.setState({isDiscontinuedHideAddToCart: false})
        }
      })
    /*const promise5 = 
        restUtils.postData(
          INVOICE_ORDER_HISTORY,
          requestPayLoad
        ).then(response=>{
          invoicedOrders = response.data.orderList
        }).catch(error=>{
          this.setState({loading:false,invoicedOrders:[]})
        })*/
    Promise.all([promise1, promise2, promise3, promise4, promise5]).then(() => {
      this.setState({
        product,
        quantity: utilities.emptyForNull(product.cart_available),
        maxQty: utilities.emptyForNull(product.maxQty),
        overrideRetail: utilities.emptyForNull(product.cart_overrideretail),
        orderHistory: orderHistory,
        invoicedOrders,
        loading: false,
        serverError: false,
        isFavorite,
        imageSrcs,
        variableMethod,
        variableRetail
      });
    });
  };

  monthlySpecialPDP(storeId, rdc, currentCartId, itemId){
    this.setState({ loading: true });
    let product = null;
    let isFavorite = false;
    const {monthlyAssmentNbr,promoNbr, monthlyPromoNbr, isMonthlyListing} = this.props.location && this.props.location.state;
    const mnthlyspcpayload={
      asstmtNbr: monthlyAssmentNbr,
      cartID: currentCartId,
      itemNbr: itemId,
      isItemDtlFromWH: "false",
      mbrNbr: storeId,
      promoNbr: isMonthlyListing?monthlyPromoNbr:promoNbr,
      rdcNbr: rdc
    }
    restUtils.postData(MONTHLY_SPECIALS_PDP, mnthlyspcpayload)
      .then(response => {
        product = response.data && response.data.MSItemDetailList[0];
        isFavorite = this.props.favorites.indexOf(parseInt(product.Item_Number)) > -1;
        let imageSrcsAlt = this.state.imageSrcs
        if(product.altImg == 1){
          imageSrcsAlt = [
            `${getImageURl()}/nsimages/${itemId}.jpg`
          ]
        }
        const monthlySplNonStockItem = product?.stocked && product?.stocked === 'Y' ? true : false
        this.setState({
          product:product, MSData: product,
          quantity: utilities.emptyForNull(product.cart_available),
          maxQty: utilities.emptyForNull(product.maxQty),
          overrideRetail: utilities.emptyForNull(product.cart_overrideretail),
          loading: false,
          isFavorite: isFavorite,
          imageSrcs: imageSrcsAlt,
          monthlySplNonStockItem
        })       
      })
      .catch(error => {
        console.log(error);
        this.setState({
          loading: false,
          serverError: true
        });
      });
  }

  toggleDiv = divId => {
    this.setState(prevState => {
      switch(divId){
        case "showSpec":
          return {
            [divId]: !prevState[divId],
            showProductDimensionSpec: !prevState[divId],
            showDetailedSpec: !prevState[divId],
            showWarrantySpec: !prevState[divId],
          }
        case "showWholesale":
          return {
            [divId]: !prevState[divId],
            showOrderingSpec: !prevState[divId],
            showShipping:!prevState[divId],
            showInvoicedOrders:!prevState[divId],
            showorderHistory: !prevState[divId],
          };
        case "showRestriction":
          return {
            [divId]: !prevState[divId],
            onlineRestriction: !prevState[divId],
          }
        case "showRetail":
          return {
            [divId]: !prevState[divId],
            showPlanograms: !prevState[divId],
            showOnHandInventory:!prevState[divId],
            posSale:!prevState[divId],
          }
        default:
           return { [divId]: !prevState[divId] };
        }
    }); 
  };

  showCompetitors() {
    this.setState({
      competitors: true,
      item_num: this.state.product.Item_Number
    });
  }

  DialogClose = () => this.setState({ competitors: false });
  getBreadcrumbLinks = (product, isMonthlyListing, isReunionListing,monthlyPromoNbr,isWow,shoppingAreaName,shoppingAreaMetaId,isCtbAssortment,assortmentId,assortmentName,ctbseleDept) => {
   let  breadcrumblinks;
    if(isReunionListing){
      breadcrumblinks = [
        { link: "/reunionOrdering", text: "REUNION HOME" }
      ]
    } else {
      breadcrumblinks = [
        { link: '/home', text: 'HOME' }
      ]
    }
    if(isWow){
      breadcrumblinks = [
        ...breadcrumblinks,
        { link: '/wow', text:'WOW' },
        ...monthlyPromoNbr?[{ link: '/wow/' + monthlyPromoNbr, text:'WOW VENDORS' }]:[]
      ]
    }else if (isMonthlyListing) {
      breadcrumblinks = [
        ...breadcrumblinks,
        { link: '/monthlyEventVendors/' + monthlyPromoNbr, text:'MONTHLY SPECIAL VENDORS' }
      ]
    }else if (isReunionListing && shoppingAreaName) {
      breadcrumblinks = [
        ...breadcrumblinks,
        { link: '/reunionOrdering/' + utilities.replaceAll(shoppingAreaName, ' ', '')+'/'+shoppingAreaMetaId+'/true', text: shoppingAreaName }
      ]
    } 
    else if (isCtbAssortment) {
      breadcrumblinks = [
        ...breadcrumblinks,
        { link: '/ctbAssortments', text: 'CTB Assortments' },
      ]
      if(!utilities.isEmptyOrNullString(product.deptName)) {
        breadcrumblinks = [
          ...breadcrumblinks,
          {
            link: '/ctbAssortments/' + ctbseleDept.trim(),
            text: ctbseleDept.trim()
          },
        ]
      }
      if(!utilities.isEmptyOrNullString(assortmentId)) {
        breadcrumblinks = [
          ...breadcrumblinks,
          {
            link: '/assortment/' + assortmentId,
            text: assortmentName
          },
        ]
      }
    }
    else {
      breadcrumblinks = [
        ...breadcrumblinks
      ]
      if(!utilities.isEmptyOrNullString(product.deptName)) {
        breadcrumblinks = [
          ...breadcrumblinks,
          {
            link: '/categories/' + product.deptName,
            text: product.deptName
          },
        ]

      }
      if(!utilities.isEmptyOrNullString(product.className)) {
        breadcrumblinks = [
          ...breadcrumblinks,
          {
            link: '/categories/' +
              product.deptName +
              '/' +
              (product.className ?
                product.className.replace(/\//g, '%2F') :
                product.className),
            text: product.className
          },
        ]

      }
      if(!utilities.isEmptyOrNullString(product.subclassName)) {
        breadcrumblinks = [
          ...breadcrumblinks,
           
        {
          link: '/categories/' +
            product.deptName +
            '/' +
            (product.className ?
              product.className.replace(/\//g, '%2F') :
              product.className) +
            '/' +
            (product.subclassName ?
              encodeURIComponent(product.subclassName) :
              product.subclassName),
          text: product.subclassName
        }
        ]
      }
    };
    breadcrumblinks = [
      ...breadcrumblinks,
      {
        link:'#',
        text:product.shortdesc
      }
    ];
    return breadcrumblinks;
  }

  mapRedirect = ()=>{
    utilities.openInNewTab(MAP_REDIRECTION_URL);
  }
  getSuggestedRetail = () => {
    const { product, variableRetail } = this.state;
    const { viewType, multiStoreDetails } = this.props;
    const getpreferValue=this.props.getprefer && this.props.getprefer.customerView ? this.props.getprefer.customerView : 'c1';
    let suggestedRetailPrices = BusinessRules.suggestedRetailPriceForPdp(product,variableRetail,viewType, multiStoreDetails,getpreferValue);
    return (
      suggestedRetailPrices.map(sr=>{
        return (
          {
            ...sr,
            price:(
              <td className='cell_tab' data-label='price'>
                <>
                  <MaskPrice
                    getprefer={this.props.getprefer &&
                      this.props.getprefer.customerView
                      ? this.props.getprefer.customerView
                      : 'c1'
                    }
                    viewType={this.props.viewType}
                    fieldVal={sr.price?sr.price:0}
                    field="Suggested Retail"
                  />
                </>
              </td>
            )
          }
        )
      })
    )
  }
  goToPreviousPage= () => {
    history.goBack()
  }
  goToPreviousCtbPage = () => {
    if (this.props.match.params.productId) {
      history.push({
        pathname: '/assortment/' + this.props.match.params.productId.toString(),
        state:{
          missing:this.state.isMissing
        }
      })
    } else {
      history.goBack()
    }
  }
  vendorPolicyRedirect = ()=>{
    utilities.openInNewTab(VENDOR_POLICY_MAP_REDIRECTION_URL);
  }
  primaryMessage = (product) => {
    if (product?.Flatbed &&  this.props?.multiStoreDetails?.isWHFromPendingCheck && this.props?.rdc === "09") {
       return  <span className="red_text_details">
          NOTE: Item requires special equipment and additional leadtime for delivery.<br />Questions concerning delivery contact the Corsicana Distribution Center  
        </span>
    }
    else if(product?.Flatbed) {
      return  <span class="red_text_details">
        NOTE: This item can only be delivered to Corsicana serviced retailers.
        The item requires special equipment and additional leadtime for
        delivery.
        <br /> Questions concerning delivery contact the Corsicana Distribution
        Center
      </span> 
    }
    
  };
  openEstimatorModel = () =>{
    this.setState({showEstimator:true})
  }
  closeEstimator = () =>{
    this.setState({showEstimator:false})
  }
 render() {
    const warehouseLink = '/all-warehouses/' + this.props.match.params.productId;
    const { product, imageSrcs, MSData } = this.state;
    const pricingDetails = product.pricing ? product.pricing : {};
    const { state = {} } = this.props.location || {};
    const {
      title = 'Warehouse Products',
      assortmentType = '',
      isReunionListing = false,
      isMonthlyListing,
      isWow,
      listType,
      muzak,
      savingsOutOfWarehouse,
      promoHistory ,
      futurePromo,
      MonthlySpecialSaving,
      MonthlyEventCost,
      MonthlyRegularCost,
      monthlyStock,
      MonthlyWHCost,
      MonthlyWHCostT1,
      monthlyPromoNbr,
      isHotDeals,
      eventFields,
      hideAddToCart,
      shoppingAreaName,
      shoppingAreaMetaId,
      isCtbAssortment,
      assortmentId,
      assortmentName,
      ctbseleDept
    } = state;
    const isDOMProduct = assortmentType && assortmentType==="DOM";
    const CentralRDC = product.quantity ? product.quantity[0] : null;
    const breadcrumbs = this.getBreadcrumbLinks(product, isMonthlyListing,isReunionListing, monthlyPromoNbr,isWow,shoppingAreaName,shoppingAreaMetaId,isCtbAssortment,assortmentId,assortmentName,ctbseleDept);
    const getpreferValue= this.props.getprefer && this.props.getprefer.customerView ? this.props.getprefer.customerView : 'c1';
    const showSuggestedRetailPrice  = shouldHideSUGG_RETAILForPreference(this.props.viewType,getpreferValue);
    const suggestedRetail = this.getSuggestedRetail();
    //const DiscontinuedMsg = product.DiscontinuedMessage ? product.DiscontinuedMessage.message : null;
    let msCheck = utilities.msExist(this.props.multiStoreDetails); 
    const savingsValue = MonthlySpecialSaving ? MonthlySpecialSaving.split('%')[0] : 0;
    const savingsValueRE = savingsOutOfWarehouse ? savingsOutOfWarehouse.split('%')[0]:0
    return (
      <>
        {this.state.loading && (
          <div>
            <Loader active />
          </div>
        )}
        { product.Item_Number ?
        <>
          <AddToCartModal
            isAddtoCartModalopen={this.state.isAddToCartModalOpen}
            closeCartModal={this.closeCartModal}
            addToCart={this.addToCart}
            product={this.state.productSelectedForModal}
            index={0}
            qty={this.state.quantity}
            or={this.state.overrideRetail}
          ></AddToCartModal>
          <MultistoreToCartModal
          isMultistoreModalOpen={this.state.isMultistoreModalOpen}
          closeMultistoreModal={this.closeMultistoreModal}
          product={this.state.productSelectedForModal}
          tableType={'productdetails'}
          quantitiesMs={this.state.quantity}
          overrideRetail={this.state.overrideRetail}
        ></MultistoreToCartModal>
          <Segment id='productDetailInfo'>
          {this.state.showScrollButton &&
            <button class="ui circular icon floated button" 
              style={{zIndex: 999,right: 10,bottom : 60,position: 'fixed'}}
              onClick={() => {this.goToSection("showTitleLinks")}}
            >
              <i aria-hidden="true" class="icon fas fa-chevron-circle-up" 
                style={{fontSize: '40px', color: 'black'}}>
              </i>
            </button>
          }
          {this.state.serverError ? (
            <InternalServerError />
          ) : (
              <>
                <div ref={el => (this.componentRef = el)}>
                  <>
                    {this.state.competitors && (
                      <div id='competitorDialog'>
                        <Dialog width='1' height='1' position='absolute'>
                          <div className='dialog_title'>Competitor Pricing</div>
                          <div className='dialog_close'
                            onClick={this.DialogClose}
                          >
                            X
                        </div>
                          <CompetitorPricing
                            item_upc={product.UpcNum}
                            pricing={product.Srp_Cost}
                          />
                        </Dialog>
                      </div>
                    )}
                    <Modal closeIcon
                       className='EstimatorPopup'
                       onClose = {this.closeEstimator}
                       closeOnDimmerClick={false}
                       open={this.state.showEstimator}>
                       <Modal.Content className=''>
                           <Freight />
                       </Modal.Content>
                     </Modal>
                    <div className={this.state.competitors ? 'grey_background' : ''}>
                      <div className=''>
                        <div>
                          <div className='page-header pdp_details-header'>
                            {title !== '' ? title : 'Warehouse Products'}
                            <span className='find_like_items'>
                             <Link to={'/categories/' +
                              encodeURIComponent(product.deptName)  +
                               '/' +
                               encodeURIComponent(product.className) +
	                               '/' +
                               encodeURIComponent(product.subclassName)
                             }
                             >
                               Find Like Items
                           </Link>
                           </span> 
                          </div>
                          {window.innerWidth > 600 && (
                            <div id='product_name_details_review'>
                              <span onClick={()=>{history.push({
                                                                pathname:'/asktruevalue/' + product.Item_Number,
                                                                state:{isTier2:isHotDeals||isMonthlyListing||isDOMProduct,isRaMembercost:assortmentType === 'RetailAssortments'}
                                                              })}}>
                                Ask TrueValue
                              </span>
                            </div>
                             )}
                          </div>
                        <div>
                          <div id='page_navigation'>
                            <BreadcrumbComp path={breadcrumbs} />
                          </div>
                          {window.innerWidth <= 600 && (
                            <div id='store_questions'>
                             <span onClick={()=>{history.push({
                                                                pathname:'/asktruevalue/' + product.Item_Number,
                                                                state:{isTier2:isHotDeals||isMonthlyListing||isDOMProduct,isRaMembercost:assortmentType === 'RetailAssortments'}
                                                              })}}>
                                Ask TrueValue
                              </span>
                            </div>
                          )}
                        </div>
                      </div>    
                     <div className='ui stackable two column grid mt-0'>
                        <div className='thirteen wide column' id='product_name_details'>
                          <span className='favorites-icon' onClick={this.toggleFavorite}>
                            <i className={classnames('heart',
                              { outline: !this.state.isFavorite },
                              'icon'
                            )}
                            />
                          </span>
                          <span className='product-name'>
                            {product.shortdesc} &nbsp;&nbsp;
                        </span>
                        { !isMonthlyListing && !isReunionListing ? 
                        <Link
                            className='vendor-span' 
                            to={{
                              pathname: '/vendor/' + utilities.replaceAll(product.Vendor_Name, '/', '%2F'),
                              state: { vendorId: product.Vendor_Id }
                               }}
                          > 
                           {product.Vendor_Name}
                           </Link> : <b> {product.Vendor_Name}</b>  }
                           <Popup
                              wide='very'
                              hoverable
                              content={<VendorDetailsPopup ID={product.Vendor_Id || product.vendorID}/>}
                              className= {window.innerWidth < 767 ? 'flowing' : ''}
                              position='bottom left'
                                trigger={
                                <Button
                                  size='mini'
                                  style = {{"margin-left":"1rem"}}
                                  content=' View Details'
                                />
                              }
                            />
                          </div>
                        <div style={{textAlign:'right',display:'flex',flexDirection:'column',alignItems:'end'}} className='backLink three wide column'>
                          {this.state.isMissing ?  <span className='back_button' style ={{float:'initial' }} onClick={this.goToPreviousCtbPage}>Back to Previous Page</span>:
                          <span className='back_button' style ={{float:'initial' }} onClick={this.goToPreviousPage}>Back to Previous Page</span>}
                          <button style={{padding:'0.4rem',margin:0,maxWidth:'13rem'}}class="ui red button" onClick={this.openEstimatorModel}>LTL Freight Estimator</button>
                        </div> 
                      </div>
                      <div className='ui stackable two column grid mt-0'>
                        <div className='thirteen wide column' id='product_name_details'>
                          <span className="product-title">
                            {product.Product_Title} &nbsp;&nbsp;
                          </span>
                        </div>
                      </div>
                  <div className='ui stackable two column grid'>
                        <div className=' sixteen wide column' id='pdp_top_details'>
                          {window.innerWidth >= 768 ? (
                            <>
                              <b style={{ color: '#cb1e00' }}>
                                {'ITEM #: ' + utilities.emptyForNull(product.Item_Number)}
                              </b>{' '}
                              &nbsp;| &nbsp;
                            <b>{'MODEL #: ' + utilities.emptyForNull(product.Model)}{' '}</b>{' '}
                              &nbsp;| &nbsp;
                            <b>{'PACK: ' +
                                  utilities.emptyForNull(product.S_Pack_Type) +
                                  utilities.emptyForNull(product.S_Pack_Qty)}
                              </b>{' '}
                              &nbsp;| &nbsp;
                              {
                                !this.state.monthlySplNonStockItem && (
                                  <>
                                      <b>
                                    {product.primaryWarehouseType === 'S' && CentralRDC && ((CentralRDC.Type === 'H') 
                                        || (CentralRDC.Type === 'S' && CentralRDC.IsHubPlusSpokeQty)) ?
                                        'Combined Available' : 'AVAILABLE'
                                      }:&nbsp;{
                                      <span className={parseInt(product.Available) > 0
                                        ? 'black_text_details'
                                        : 'red_text_details'}>
                                          {utilities.checkForHubAndSpokeIndictor(product)}
                                      </span> }
                                    </b>{' '}
                                    &nbsp;| &nbsp;
                                  </>
                                )
                              }
                            <b className={parseInt(product.onShipLater) > 0
                                              ? 'red_text_details'
                                              : 'black_text_details'
                              }>ON SHIP LATER: {product.onShipLater}</b> 
                              {
                                 !this.state.monthlySplNonStockItem && (
                                  <>
                                    &nbsp;| &nbsp;
                                    <b> DEMAND CLASS:{' '}
                                    {utilities.emptyForNull(
                                      product.demandclass === 'N'
                                        ? 'Non-Classified'
                                        : product.demandclass
                                    )}
                                  </b>{' '}
                                  &nbsp; 
                                  </>
                              )}
                            
                              <ReactToPrint
                                pageStyle='@page{size:potrait;}'
                                trigger={() => (
                                  <i className='print icon' id='printicon'></i>
                                )}
                                content={() => this.componentRef}
                              /> <br />
                              <div className='totalRDC'>
                                {
                                 !this.state.monthlySplNonStockItem && (
                                  <b> Stock Status:{' '}
                                  {!utilities.isEmptyOrNullString(product.whStockStatusMessage) ? product.whStockStatusMessage : '-'}
                                  </b>
                                 )}
                                {product.alternateItem?.Number && product.alternateItem?.Number > 0 ? (
                                  <p className="alternativeItem">
                                    | &nbsp;
                                    <strong>{product.alternateItem.Label}</strong>
                                    <Link class="altItemNo" to={{ pathname: '/product/' + product.alternateItem.Number}}>
                                    {product.alternateItem.Number}
                                    </Link>
                                    <b className="altItemName">{product.alternateItem.Name}</b>
                                    <span className='alternativeItemImg'>
                                      {renderImages.getImage({
                                          src: getImageLink(product.alternateItem.Number),
                                          className: '',
                                          alt: 'Image'
                                      })}
                                    </span>
                                  </p>
                                ): null}
                                
                              </div>
                              {
                                 !this.state.monthlySplNonStockItem && (
                                  <>
                              <div className='totalRDC'>
                                <b> TOTAL ON ORDER TO RDC:{' '}
                                  {utilities.hyphenForNull(
                                    product.totalOnOrderToRDC
                                  )}
                                </b>{' '}
                                &nbsp;
                                   {product.etaWithQtyList && product.etaWithQtyList.length > 0 ? 
                                        <b> | &nbsp;
                                          {product.etaWithQtyList.join(' | ')}
                                        </b>
                                      : ''
                                   }
                                   
                                   {!utilities.isEmptyOrNullString(product?.estimatedArrivalDate) && (
                                     <>
                                   &nbsp;| &nbsp;  
                                      <b className="">Anticipated Recovery Date:{' '}
                                      {product.estimatedArrivalDate}                                     
                                      </b>
                                     </>
                                     
                                   )}
                                   
                                   {/*<b> Referral Item #:{' '}
                                    {product.referral_item_nbr ? product.referral_item_nbr : ''}
                                   </b>*/}
                              </div>
                              </>
                              )}
                              <div className='column pt-2'>
                              {(product.primaryWarehouseType === 'S' && CentralRDC && ((CentralRDC.Type === 'H') 
                                  || (CentralRDC.Type === 'S' && CentralRDC.IsHubPlusSpokeQty))) ? 
                                       <>
                                       <p className='address'>
                                        <span>
                                          {!product.rdcDoesNotStockItem && (
                                             <>
                                               {`Warehouse: ${this.props.rdc} ${utilities.emptyForNull(product.rdc_name)},
                                               ${utilities.emptyForNull(product.rdc_state)}`}
                                               {'     ,     '}
                                             </>
                                           )} 
                                          {`Hub Warehouse: ${utilities.emptyForNull(product.hubrdcname)}`}
                                          {'  '} 
                                          {utilities.isEmptyOrNullString(assortmentType) &&
                                            !isReunionListing && !isMonthlyListing && !this.state.isDiscontinuedHideAddToCart &&(
                                              <Link className='check_all_warehouse' to={warehouseLink} >
                                                <u>Check All Warehouses</u>
                                              </Link>
                                            )
                                          }
                                        </span>
                                        </p>
                                       </>
                                      : <>
                                    {CentralRDC && CentralRDC.Type === 'C' &&
                                      !utilities.isEmptyOrNullString(CentralRDC.CsRdc) ? (
                                        <p className='address'>
                                          Warehouse: Central Shipped from RDC{' '}
                                          {CentralRDC.CsRdc}
                                          <span>
                                            {(!isReunionListing && !this.state.isDiscontinuedHideAddToCart && 
                                            <Link className='check_all_warehouse' to={warehouseLink} >
                                                <u>Check All Warehouses</u>
                                            </Link>)}
                                          </span>
                                        </p>
                                      ) : (
                                        <p className='address'>
                                          {(product && product.rdcDoesNotStockItem) ?
                                            <span> NOT STOCKED {'  '} &nbsp;</span>
                                            :
                                            <span>
                                              {`Warehouse: ${this.props.rdc} ${utilities.emptyForNull(product.rdc_name)},
                                              ${utilities.emptyForNull(product.rdc_state)}`}
                                              {'  '} &nbsp;
                                            </span>
                                          }
                                          {utilities.isEmptyOrNullString(assortmentType) &&
                                            !isReunionListing && !isMonthlyListing && (
                                              <Link className='check_all_warehouse' to={warehouseLink} >
                                                <u>Check All Warehouses</u>
                                              </Link>
                                            )
                                          }
                                        </p>
                                      )
                                    }
                                    </>
                                }
                              </div>
                            </>
                          ) : (
                              <div>
                                <span className='card_name'>ITEM#:</span>
                                <b className='First_level_details'>
                                  {utilities.emptyForNull(product.Item_Number)}
                                </b>
                                <br />
                                <span className='card_name'>MODEL#:</span>
                                <b className='First_level_details'>
                                  {utilities.emptyForNull(product.Model)}
                                </b>
                                <br />
                                <span className='card_name'>PACK:</span>
                                <b className='First_level_details'>
                                  {utilities.emptyForNull(product.S_Pack_Type)}
                                  {product.S_Pack_Qty}
                                </b>
                                <br />
                                {
                                !this.state.monthlySplNonStockItem && (
                                  <>
                                  <span id='MobileAvailable'>
                                {product.primaryWarehouseType === 'S' && CentralRDC && ((CentralRDC.Type === 'H') 
                                  || (CentralRDC.Type === 'S' && CentralRDC.IsHubPlusSpokeQty)) ?
                                  'Combined Available' : 'AVAILABLE'
                                  }
                                </span>
                                <b className={ parseInt(product.Available) > 0
                                              ? ''
                                              : 'First_level_details_red'
                                  } id='Mobileqty'
                                >
                                  {utilities.checkForHubAndSpokeIndictor(product)}
                                </b>
                                <br />
                                </>
                                )}
                                {!utilities.isEmptyOrNullString(
                                  product.aliasSku
                                ) && (
                                    <>
                                      <span className='card_name'>Alternate SKU#:</span>
                                      <b className='First_level_details'>
                                        {parseFloat(product.aliasSku) !== 0 && product.aliasSku}
                                      </b>
                                      <br />
                                    </>
                                  )}
                                <span className='card_name'>ON SHIP LATER:</span>
                                <b className='First_level_details'>
                                  {product.onShipLater}
                                </b>
                                <br />
                                {
                                   !this.state.monthlySplNonStockItem && (
                                  <>
                                  <span className='card_name'>DEMAND CLASS:</span>
                                  <b className='First_level_details'>
                                    {product.Demand}
                                  </b>
                                  <br />
                                <span className='card_name'>Stock Status:</span>
                                <b className='First_level_details'>
                                {!utilities.isEmptyOrNullString(product.whStockStatusMessage) ? product.whStockStatusMessage : '-'}
                                </b>
                                </>
                                )}
                                {product.alternateItem?.Number && product.alternateItem?.Number > 0 ? (
                                  <p className="alternativeItem">
                                    <strong>{product.alternateItem.Label}</strong>
                                    <Link class="altItemNo" to={{ pathname: '/product/' + product.alternateItem.Number}}>
                                    {product.alternateItem.Number}
                                    </Link>
                                    <b className="altItemName">{product.alternateItem.Name}</b>
                                    <span className='alternativeItemImg'>
                                      {renderImages.getImage({
                                          src: getImageLink(product.alternateItem.Number),
                                          className: '',
                                          alt: 'Image'
                                      })}
                                    </span>
                                  </p>
                                ): null}
                                <br />
                                {
                                   !this.state.monthlySplNonStockItem && (
                                   <>
                                  <span className='card_name'>
                                    TOTAL ON ORDER TO RDC:
                                  </span>
                                  <b className='First_level_details'>
                                    {utilities.emptyForNull(
                                      product.totalOnOrderToRDC
                                    )}
                                  </b>
                                  <br />
                                  </>
                                )}
                                <span className='card_name'>
                                  
                                {product.etaWithQtyList && product.etaWithQtyList.length > 0 ? 
                                    <b>
                                      {product.etaWithQtyList.join(',')}
                                    </b>
                                  : ''
                                   }
                                </span>
                                <span className='card_name'>
                                  {!utilities.isEmptyOrNullString(product?.estimatedArrivalDate) && ( 
                                        <b className="">Anticipated Recovery Date:{' '}
                                        {product.estimatedArrivalDate}                                     
                                        </b>
                                      
                                  )}
                                </span>
                                <br />
                                <span className='card_name'>
                                {(product.primaryWarehouseType === 'S' && CentralRDC && ((CentralRDC.Type === 'H') 
                                  || (CentralRDC.Type === 'S' && CentralRDC.IsHubPlusSpokeQty))) ? 
                                       <>
                                       <p className='address'>
                                        <span>
                                          {!product.rdcDoesNotStockItem && (
                                             <>
                                               {`Warehouse: ${this.props.rdc} ${utilities.emptyForNull(product.rdc_name)},
                                               ${utilities.emptyForNull(product.rdc_state)}`}
                                               {'     ,     '}
                                             </>
                                           )} 
                                          {`Hub Warehouse: ${utilities.emptyForNull(product.hubrdcname)}`}
                                          {'  '} 
                                        </span>
                                        </p>
                                       </>
                                      : <>
                                      {CentralRDC &&
                                        !utilities.isEmptyOrNullString(CentralRDC.CsRdc) ? (
                                          <p className='address'>
                                            Warehouse: Central Shipped from RDC{' '}
                                            {CentralRDC.CsRdc}
                                          </p>
                                        ) : (
                                          <p className='address'>
                                            {(product && product.rdcDoesNotStockItem) ?
                                              <p> NOT STOCKED {'  '} &nbsp;</p>
                                              :
                                              <p className='address'>
                                                {`Warehouse: ${this.props.rdc} ${utilities.emptyForNull(product.rdc_name)},
                                                ${utilities.emptyForNull(product.rdc_state)}`}
                                                {'  '} &nbsp;
                                              </p>
                                            }
                                          </p>
                                        )}
                                        </>
                                  }
                                </span>
                                <b className='First_level_details'>
                                  {CentralRDC && !utilities.isEmptyOrNullString(CentralRDC.CsRdc)
                                    ? ''
                                    : utilities.isEmptyOrNullString(assortmentType) &&
                                    !isReunionListing && !isMonthlyListing && !this.state.isDiscontinuedHideAddToCart && (
                                      <Link className='check_all_warehouse' to={warehouseLink}>
                                        <u>Check All Warehouses</u>
                                      </Link>
                                      )
                                      }
                                </b>
                                <ReactToPrint
                                pageStyle='@page{size:potrait;}'
                                trigger={() => (
                                  <i className='print icon' id='printicon'></i>
                                )}
                                content={() => this.componentRef}
                              />
                                <br />
                              </div>
                            )}
                        </div>
                      </div>
                      {window.innerWidth >= 768 &&
                        !utilities.isEmptyOrNullString(product.aliasSku) && (
                          <p className='Alias_sku'>
                            Alternate SKU#: {parseFloat(product.aliasSku) !== 0 && product.aliasSku}
                          </p>
                        )}
                      <div className='displayFlex ui stackable sixteen wide two column grid'>
                        <div id="pdpImageDiv" className={window.innerWidth > 600
                          ? 'pdpImage five wide column'
                          : 'pdpImage column'
                        }
                        >
                          <ImageGallery images={imageSrcs} />
                        </div>
                        <div className={window.innerWidth > 768
                          ? window.innerWidth > 1024
                            ? 'eleven wide column'
                            : 'sixteen wide column'
                          : 'column'
                        } id='prodDescription'
                        >
                          <p className='description'>
                            <b>{product.Product_Description ||product.Long_Description}</b>
                          </p>
                          <div className='ui stackable one column grid'>
                            <div className='sixteen wide column'>
                            {
                              this.state.showBullets ? (
                              <ul>
                                {product.Product_Bullets && product.Product_Bullets.map(bullet => {
                                  return ( <li> {bullet}</li> )})
                                }
                                {product.Product_Bullets && product.Product_Bullets.length > 4 ?
                                  <button onClick={this.hideProductBullets} >Show less</button> 
                                  : '' 
                                }
                              </ul>) :(
                              <ul>
                                {product.Product_Bullets && product.Product_Bullets.slice(0,4).map((bullet,bullet_index) => {
                                 return ( <li> {bullet} </li>)})
                                }
                                {product.Product_Bullets && product.Product_Bullets.length > 4 ?
                                  <button onClick={this.showProductBullets}>Show more</button>
                                  : ''
                                }
                              </ul>) 
                            }
                            </div>
                          </div>
                          <div className='ui stackable two column grid'>
                            <div className='ten wide column'>
                              {this.props.viewType !== CUSTOMER_VIEW &&
                                pricingDetails.tiered ? (
                                  <>
                                    {(this.props.viewType !== CUSTOMER_VIEW && (this.props.multiStoreDetails.access !== 1 || this.props.multiStoreDetails.CatMbr))
                                      ? <TierTableNew pricing={pricingDetails} monthly_special_t2 = { isMonthlyListing ? MonthlyWHCost : 0}  monthly_special_t1 = {isMonthlyListing ? MonthlyWHCostT1: 0 } />
                                      : " "
                                    }
                                  </>
                                ) : this.props.viewType !== CUSTOMER_VIEW &&
                                !pricingDetails.tiered ? (
                                <ShowPromoCostExpDetails
                                  pricing={pricingDetails}
                                />):""
                              }
                              <div className='indicators-div'>
                                <ProductIndicators product={product} isPdp = {true} />
                              </div>
                              <br />
                              {product.prop65 === 'Y' && (
                                <div className='warning_container'>
                                  {renderImages.getImage({
                                    src: warning,
                                    className: 'warning_icon',
                                    alt: 'warn'
                                  })}
                                  <span className='warning prop65_color'>
                                    Warning: Prop65{' '}{product.prop65Message &&`-${product.prop65Message}`}
                                  </span>
                                </div>
                              )}
                               {/* Substitute/Referral item for PDP */}  
                               { (product.SubstituteItems && product.SubstituteItems.length > 0) &&  
                                   <div className='substituteContainer'>                           
                                       <ul>  
                                         {                 
                                           product.SubstituteItems.map((item,index) => {                          
                                            const subDate = utilities.getDateFromTimeStampShip(item.effectiveDate);
                                            if(parseInt(item.sub_type_cd) === 3) {
                                             return <li className="substituteItems"> Referral Item for<Link class="altItemNo" to={{ pathname: '/product/' + item.itemNumber}}>
                                               {item.itemNumber}
                                               </Link>since {subDate}</li>
                                               } else {
                                               return <li className="substituteItems"> Substitute Item for<Link class="altItemNo" to={{ pathname: '/product/' + item.itemNumber}}>
                                                 {item.itemNumber}
                                                 </Link>since {subDate}</li>
                                               }
                                             }
                                           ) 
                                         } 
                                       </ul>
                                   </div>
	                                 }
                              {
                                  product.Restrictions && product.Restrictions.length > 0 && product.Restrictions.map((msg) =>{
                                  let dealerProgramCodeCheck = false;
                                  let displayMessage = msg.Message;
                                  if(msg.Type === "Dealer-Program" && displayMessage){
                                    dealerProgramCodeCheck = displayMessage.includes('{@ShowSubscribeLink}')
                                    displayMessage = displayMessage.split('{@')[0]
                                  }
                                  return (
                                    <>
                                    <div className='d-left'>
                                      <p className='Alert'>
                                        { !dealerProgramCodeCheck ? displayMessage : 
                                          <span>
                                            {displayMessage} To participate in the program, please click <a onClick= {() => window.open('/content/dealer-programs')} className='dealer-program'>here</a> to access the Dealer Program enrollment information.
                                          </span>
                                        }
                                      </p>
                                      <br/>
                                    </div>
                                    </>
                                  )
                                })
                              }
                            </div>
                            <div className='six wide column'>
                              <table id='retail_details'>
                              <tbody>
                                  {!pricingDetails.tiered &&
                                    (
                                      <>
                                        {(this.props.viewType !== CUSTOMER_VIEW && (this.props.multiStoreDetails.access !== 1 || this.props.multiStoreDetails.CatMbr))
                                          ? (this.props.multiStoreDetails.access !== 1 && this.props.multiStoreDetails.CatMbr)
                                            ? <tr className='suggestedRetail'>
                                              <td className='suggestedRetailRow' data-label='Memeber Cost'> Member Cost</td>
                                              <td>
                                                <MaskPrice
                                                  getprefer={
                                                    this.props.getprefer &&
                                                      this.props.getprefer.customerView
                                                      ? this.props.getprefer.customerView
                                                      : 'c1'
                                                  }
                                                  viewType={this.props.viewType}
                                                  fieldVal={product.pricing ? product.pricing.Member_Cost : ''}
                                                  field='Member Cost'
                                                />
                                              </td>
                                            </tr>
                                            : <>
                                              <tr className='suggestedRetail'>
                                                <td className='suggestedRetailRow' data-label='Memeber Cost'> Member Cost</td>
                                                <td>
                                                  <MaskPrice
                                                    getprefer={
                                                      this.props.getprefer &&
                                                        this.props.getprefer.customerView
                                                        ? this.props.getprefer.customerView
                                                        : 'c1'
                                                    }
                                                    viewType={this.props.viewType}
                                                    fieldVal={this.props.viewType !== CUSTOMER_VIEW ?
                                                      product.pricing ? product.pricing.Member_Cost : ''
                                                      : product.pricing ? product.pricing.srCost : ''}
                                                    field='Member Cost'
                                                  />
                                                </td>
                                              </tr>
                                            </>
                                          :
                                          ""
                                        }
                                      </>
                                    )}
                                    {suggestedRetail.map((sr,index)=>{
                                      return ( showSuggestedRetailPrice ?
                                        <tr className='suggestedRetail'>
                                          <td className='suggestedRetailRow' data-label='Suggested Retail'>
                                            {index===0 &&
                                              <>
                                                <i 
                                                  className= {this.props.viewType === CUSTOMER_VIEW ? '': 'fas fa-search-dollar search_dollar_img'}
                                                  onClick={() => this.showCompetitors(product.Item_Number)}
                                                ></i>
                                                {' '}
                                              </>
                                            }
                                            {sr.label}
                                          </td>
                                          {sr.price}
                                        </tr> : ""
                                      )
                                    })}

                                  {this.props.viewType !== CUSTOMER_VIEW && (
                                    <tr>
                                      <td className='suggestedRetailRow' data-label='Multiplier'> Multiplier </td>
                                      <td className='cell_tab'>{checkDataSource(product.Multiplier, product.dataSource)}</td>
                                    </tr>
                                  )}

                                  {this.props.viewType !== CUSTOMER_VIEW && (
                                    <>
                                      {(this.props.multiStoreDetails.access !== 1 || (this.props.multiStoreDetails.access === 1 && this.props.multiStoreDetails.CatMbr)) && this.props.onVariablePricing &&
                                        <tr>
                                          <td className='suggestedRetailRow' data-label='Variable Retail'> Variable Retail </td>
                                          <td className='cell_tab' data-label='price'>{ this.state.variableRetail ? `$${utilities.emptyForNull(this.state.variableRetail)}`: ''}</td>
                                        </tr>
                                      }
                                      { this.props.onVariablePricing &&
                                      <tr>
                                        <td className='suggestedRetailRow' data-label='Variable Method'> Variable Method</td>
                                        <td className='cell_tab' data-label='price'> {utilities.emptyForNull(this.state.variableMethod)}</td>
                                      </tr>
                                      }
                                      {(this.props.multiStoreDetails.access !== 1 || (this.props.multiStoreDetails.access === 1 && this.props.multiStoreDetails.CatMbr)) &&
                                      (!isMonthlyListing && !isReunionListing) &&
                                        <tr>
                                          <td className='suggestedRetailRow' data-label='Last Promo Price'> Last Promo Price</td>
                                          <td className='cell_tab' data-label='price'>
                                            {product.lastPromoPrice
                                              ? '$' + parseFloat(product.lastPromoPrice).toFixed(2)
                                              : ''
                                            }
                                          </td>
                                        </tr>
                                      }
                                      {isDOMProduct && !utilities.isEmptyOrNullString(muzak) &&
                                        <tr>
                                          <td className='suggestedRetailRow' data-label='Muzak'> 
                                            Muzak
                                          </td>
                                          <td className='cell_tab' data-label='muzak'> 
                                            {utilities.emptyForNull(muzak)}
                                          </td>
                                        </tr>
                                      }
                                      {isDOMProduct && !utilities.isEmptyOrNullString(savingsValueRE) &&
                                        <tr>
                                          <td className='suggestedRetailRow' data-label='SavingsOutOfWh'> 
                                            Savings out of Warehouse
                                          </td>
                                          <td className='cell_tab cost_red_color' data-label='savings-out-of-WH'>                                            
                                            {utilities.hyphenForZero(savingsValueRE && Number(savingsValueRE) > 0 ? `${Number(savingsValueRE).toFixed(2)}%`: '-')}
                                          </td>
                                        </tr>
                                      }
                                      {isDOMProduct && !utilities.isEmptyOrNullString(promoHistory) &&
                                        <tr>
                                          <td className='suggestedRetailRow' data-label='PromoHistory'> 
                                            Promo History
                                          </td>
                                          <td className='cell_tab' data-label='promo-history'> 
                                            {utilities.emptyForNull(promoHistory)}
                                          </td>
                                        </tr>
                                      }
                                      {isDOMProduct && !utilities.isEmptyOrNullString(futurePromo) &&
                                        <tr>
                                          <td className='suggestedRetailRow' data-label='FuturePromo'> 
                                            Future Promo
                                          </td>
                                          <td className='cell_tab' data-label='future-promo'> 
                                            {utilities.emptyForNull(futurePromo)}
                                          </td>
                                        </tr>
                                      }
                                      {isMonthlyListing && !utilities.isEmptyOrNullString(MonthlySpecialSaving) &&
                                        <tr>
                                          <td className='suggestedRetailRow' data-label='Savings'> 
                                            Savings
                                          </td>
                                          <td className='cell_tab cost_red_color' data-label='future-promo'> 
                                          {utilities.hyphenForZero(savingsValue && Number(savingsValue) > 0 ? `${Number(savingsValue).toFixed(2)}%`: '-')}
                                          </td>
                                        </tr>
                                      }
                                      {isMonthlyListing && !utilities.isEmptyOrNullString(MonthlyEventCost) &&
                                        <tr>
                                          <td className='suggestedRetailRow' data-label='Event-cost'> 
                                            Event Cost
                                          </td>
                                          <td className='cell_tab' data-label='future-promo'> 
                                            {'$'+utilities.emptyForNull(utilities.toIntlNumberFormat(MonthlyEventCost))}
                                          </td>
                                        </tr>
                                      }
                                      {isMonthlyListing && !utilities.isEmptyOrNullString(MonthlyWHCost) &&
                                        <tr>
                                          <td className='suggestedRetailRow' data-label='WH-cost'> 
                                            {utilities.getLabelsByType(this.props.viewType,"REGULAR_COST")}
                                          </td>
                                          <td className='cell_tab' data-label='future-promo'> 
                                          {'$'+utilities.emptyForNull(utilities.toIntlNumberFormat(MonthlyWHCost))}
                                          </td>
                                        </tr>
                                      }
                                      {/* Mean time we hide the code based on 8.5 monthly spl release request later we discuss and show it */}
                                      {/*isMonthlyListing && !utilities.isEmptyOrNullString(MonthlyRegularCost) &&
                                        <tr>
                                          <td className='suggestedRetailRow' data-label='Regular-warehouse-cost'> 
                                            Regular Warehouse Cost
                                          </td>
                                          <td className='cell_tab' data-label='future-promo'> 
                                            {utilities.emptyForNull(utilities.changePriceToNumber(MonthlyRegularCost))}
                                          </td>
                                        </tr>
                                    */}
                                      {isMonthlyListing && !utilities.isEmptyOrNullString(monthlyStock) &&
                                        <tr>
                                          <td className='suggestedRetailRow' data-label='non-Stocked'> 
                                            Non Stocked
                                          </td>
                                          <td className='cell_tab' data-label='future-promo'> 
                                            {utilities.emptyForNull(monthlyStock)}
                                          </td>
                                        </tr>
                                      }
                                      {
                                        !_.isEmpty(eventFields) && eventFields.map(field=>{
                                          return (
                                            <tr>
                                              <td className='suggestedRetailRow'> 
                                                {field.fieldName}
                                              </td>
                                              <td className={ isReunionListing && field.fieldName === "Savings" ? 'cell_tab cost_red_color' :'cell_tab'} data-label='future-promo'> 
                                                {utilities.emptyForNull(field.value)}
                                              </td>
                                            </tr>
                                          )
                                        })
                                      }
                                      <tr>
                                        <td className='suggestedRetailRow' data-label='Visibility Indicator'> Visibility Indicator</td>
                                        <td className='cell_tab' data-label='Item'>
                                          {product.rtl_cd && product.rtl_cd !== ''
                                            ? visibilityIndicators[product.rtl_cd]
                                            : ''
                                          }
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                  {/* {this.props.viewType !== CUSTOMER_VIEW && (
                                    <tr>
                                      <td className='suggestedRetailRow' data-label='DS Cost'> DS Cost </td>
                                      <td className='cell_tab' data-label='price'>{checkDSCost(product.DSCost)}</td>
                                    </tr>
                                  )} */}
                                  {!assortmentType &&
                                    !isReunionListing &&
                                    !isWow && 
                                    !hideAddToCart &&
                                    !isMonthlyListing &&
                                    !this.state.isDiscontinuedHideAddToCart && 
                                    this.props.viewType !== CUSTOMER_VIEW && (
                                      <tr>
                                        <td className='suggestedRetailRow' data-label='Override Retail'> Override Retail</td>
                                        <td className='cell_tab' data-label='price'>
                                          ${' '}<input
                                            maxLength={7}
                                            className='qty-box'
                                            type='text'
                                            name='overrideRetail'
                                            onChange={this.handleFieldChange}
                                            value={this.state.overrideRetail}
                                          />
                                        </td>
                                      </tr>
                                    )
                                  }
                                  {!assortmentType && !isReunionListing && !isMonthlyListing && !isWow  && !hideAddToCart && !this.state.isDiscontinuedHideAddToCart && (
                                    <tr>
                                      <td className='suggestedRetailRow' data-label='Quantity' >
                                        {this.state.maxQty > 0 ?
                                         <>Quantity<span className='cost_red_color'> (Max. Qty. = {this.state.maxQty})</span></> : 'Quantity'}
                                      </td>
                                      <td className='cell_tab' data-label='item'>
                                        <input
                                          maxLength={7}
                                          className='qty-box'
                                          type='text'
                                          name='quantity'
                                          id='pdpQuantity'
                                          onChange={this.handleFieldChange}
                                          value={this.state.quantity}
                                          onKeyDown = {(e)=>this.handleKeyDown(e,product)}
                                          autoFocus={this.props.getprefer && this.props.getprefer.cursorPlacement ==='Q' ? true : false}
                                        />
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                              {!assortmentType && !isReunionListing && !isWow && !hideAddToCart && !this.state.isDiscontinuedHideAddToCart && !isMonthlyListing && (
                                <>
                                  {this.props.multiStoreDetails.access !== 1 &&
                                  <>
                                  {msCheck && msCheck > 1 ?
                                    !this.props.isReunionOnlyStore ?
                                    <button
                                      className='fluid ui red button'
                                      id='button_grid'
                                      onClick={() =>
                                      this.multistoreHandleCarts(product)
                                    }
                                  >
                                    Add to Store
                                  </button>
                                    :<>
                                        <button className='fluid red ui button' disabled>Add to Store</button>
                                        <p class="disabledMessage">*Ordering restricted</p>
                                      </>
                                  :!this.props.isReunionOnlyStore ?
                                    <button className='fluid ui red button' id='button_grid' onClick={this.handleAddToCart}>
                                      Add to Cart
                                    </button>
                                    :<>
                                      <button className='fluid red ui button' disabled>Add to Cart</button>
                                      <p class="disabledMessage">*Ordering restricted</p>
                                    </>
                                  }
                                  </>
                                  }
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div className='season_dates'>
                        {product.seasonStartDate && (
                          <span>
                            Season Start:{' '}
                            {utilities.getDateFromTimeStamp(product.seasonStartDate)}
                          </span>
                        )}
                        {product.seasonEndDate && (
                          <span>
                            , Season End:{' '}
                            {utilities.getDateFromTimeStamp(product.seasonEndDate)}
                          </span>
                        )}
                      </div>
                      <div className="primary_wh_message">
                        {this.primaryMessage(product)}
                      </div>

                      {/*<div className="primary_wh_message">
                        {product.altNbr &&
                          <>
                            <span className="red_text_details">Rec. Alt Sku:
                              <u><Link to={'/product/' + product.altNbr}> {product.altNbr}</Link></u>
                            </span>
                            <span className='altrnateNbr'>{product.altNbrDescription}</span>
                          </>
                        }
                      </div>*/}

                      {/*<div className='Discontinued_and_find_like_items'>
                        {product.DiscontinuedMessage ? (
                          <span className='red_text_details'>
                            {product.DiscontinuedMessage.message} <br/>
                            <Link className='red_text_details' to={'/product/' + product.DiscontinuedMessage.substitute_item}>
                              {product.DiscontinuedMessage.substitute_item ? `${product.DiscontinuedMessage.sub_type_cd === "3"? 'REFERRAL ITEM':'SUBSTITUTE ITEM:'} ${product.DiscontinuedMessage.substitute_item}`:''}
                            </Link>
                          </span>
                        ) : <></>
                        }
                        {(product.SubstituteItems && product.SubstituteItems.length > 0) ?
                          product.SubstituteItems.map(refItem => {
                            return (
                              <span className='red_text_details'>
                                Referral Item for
                                <Link to={`/product/${refItem.itemNumber}`}>
                                  {` ${refItem.itemNumber} `}
                                </Link>
                                since {utilities.getDateFromTimeStamp(refItem.effectiveDate)}<br />
                              </span>
                            )
                          }) : <></>
                        }
                      </div>*/}
                      <div className='ui stackable one column grid'
                        style={window.innerWidth < 859 ? {} : { padding: '0rem 6rem' }}
                      >
                        <div className='sixteen column' id='Detailed_specification'>
                          <div style={{marginBottom : '0.5rem'}} ref={this.refTitleLinks}>
                            {(this.props.viewType === CUSTOMER_VIEW) ||
                              ( (product.DimensionAttributes&& Object.keys(product.DimensionAttributes).length > 0) ||
                                (product.FinelineAttributes && Object.keys(product.FinelineAttributes).length > 0) ||
                                (product.WarrantyAttributes && Object.keys(product.WarrantyAttributes).length > 0)
                              ) ?
                              (
                                <span className='link_button' onClick={() => this.goToSection("showSpec")}>
                                SPECIFICATIONS
                                </span>
                              ):''
                            }
                            {this.props.viewType !== CUSTOMER_VIEW &&
                              <span className='link_button' onClick={() => this.goToSection("showWholesale")}>WHOLESALE</span>
                            }
                            <span className='link_button' onClick={() => this.goToSection("showRestriction")}>REGULATIONS AND RESTRICTIONS</span>
                            {this.props.viewType !== CUSTOMER_VIEW &&
                              <span className='link_button' onClick={() => this.goToSection("showRetail")}>RETAIL</span>
                            }
                            {this.props.viewType === CUSTOMER_VIEW &&
                              <span className='link_button' onClick={() => this.goToSection("showStoreInformation")}>STORE INFORMATION</span>
                            }
                          </div>
                        </div>
                      </div>
                      <div className='ui stackable one column grid whiteback'
                        style={window.innerWidth < 859 ? {} : { padding: '0rem 6rem' }}
                      >
                        <div className='sixteen column' style={{marginTop: '1rem'}}id='Detailed_specification'>
                        </div>
                      </div>
                      {(this.props.viewType === CUSTOMER_VIEW ) ||
                        ( (product.DimensionAttributes && Object.keys(product.DimensionAttributes).length > 0) ||
                          (product.FinelineAttributes && Object.keys(product.FinelineAttributes).length > 0) ||
                          (product.WarrantyAttributes && Object.keys(product.WarrantyAttributes).length > 0)
                        ) ?
                        (
                        <div className='ui stackable one column grid whiteback'
                          style={window.innerWidth < 859 ? {} : { padding: '0rem 6rem' }}
                        >
                          <div className='sixteen column' id='Detailed_specification'>
                            <div className='productSpecification' >
                                <span ref={this.refSpec}>SPECIFICATIONS </span>
                                <span>
                                  <i className={classnames(
                                    `${this.state.showSpec ? 'minus' : 'plus'}`,
                                    'icon',
                                    'fl-r'
                                  )}
                                    onClick={() => this.toggleDiv('showSpec')}
                                  ></i>
                                </span>
                            </div>
                            {this.state.showSpec ? (
                              <>
                                { product.DimensionAttributes 
                                && Object.keys(product.DimensionAttributes).length > 0 ? (
                                  <div className='productDetailSpecification'
                                      colSpan={this.props.viewType === CUSTOMER_VIEW ? '10' : '4'}
                                  >
                                    <span className='productSpecSubTitle'> Dimensions </span>
                                    <span>
                                      <i className={classnames(
                                        `${this.state.showProductDimensionSpec ? 'minus' : 'plus'}`,
                                        'icon',
                                        'fl-r'
                                      )}
                                        onClick={() => this.toggleDiv('showProductDimensionSpec')}
                                      ></i>
                                    </span>
                                    {this.state.showProductDimensionSpec ? (
                                      <ProductDimensionSpecTable
                                        getprefer={
                                          this.props.getprefer &&
                                            this.props.getprefer.customerView
                                            ? this.props.getprefer.customerView
                                            : 'c1'
                                        }
                                        product={product}
                                        mapRedirect={this.mapRedirect}
                                        isCustomerView={this.props.viewType === CUSTOMER_VIEW}
                                        multiStoreDetails={this.props.multiStoreDetails}
                                        access={this.props.multiStoreDetails.access}
                                        quantity={this.state.quantity}
                                      >  
                                      </ProductDimensionSpecTable>
                                    )
                                    : (
                                      <tbody />
                                    )}
                                  </div>
                                ):''}
                                {product.FinelineAttributes 
                                && Object.keys(product.FinelineAttributes).length > 0 ? (
                                <div className='productDetailSpecification'  ref={this.refDetails}
                                  colSpan={this.props.viewType === CUSTOMER_VIEW ? '10' : '4'}
                                >
                                  <span className='productSpecSubTitle' > Details </span>
                                  <span>
                                    <i className={classnames(
                                      `${this.state.showDetailedSpec ? 'minus' : 'plus'}`,
                                      'icon',
                                      'fl-r'
                                    )}
                                      onClick={() => this.toggleDiv('showDetailedSpec')}
                                    ></i>
                                  </span>
                                  {this.state.showDetailedSpec
                                    ? (
                                        <DetailedSpecTable
                                          getprefer={
                                            this.props.getprefer &&
                                              this.props.getprefer.customerView
                                              ? this.props.getprefer.customerView
                                              : 'c1'
                                          }
                                          product={product}
                                          mapRedirect={this.mapRedirect}
                                          isCustomerView={this.props.viewType === CUSTOMER_VIEW}
                                          multiStoreDetails={this.props.multiStoreDetails}
                                          access={this.props.multiStoreDetails.access}
                                          quantity={this.state.quantity}
                                          viewType = {this.props.viewType}
                                        ></DetailedSpecTable>
                                      )
                                      : (
                                        <tbody />
                                      )
                                    }
                                  </div>
                                ) : ''}
                                {product.WarrantyAttributes 
                                  && Object.keys(product.WarrantyAttributes).length > 0 ? (
                                  <div className='productDetailSpecification' ref={this.refWarranty}
                                    colSpan={this.props.viewType === CUSTOMER_VIEW ? '10' : '4'}
                                  >
                                    <span className='productSpecSubTitle'> Warranty/Certifications </span>
                                    <span>
                                      <i className={classnames(
                                        `${this.state.showWarrantySpec ? 'minus' : 'plus'}`,
                                        'icon',
                                        'fl-r'
                                      )}
                                        onClick={() => this.toggleDiv('showWarrantySpec')}
                                      ></i>
                                    </span>
                                    {this.state.showWarrantySpec
                                      ? (
                                        <table className='ui celled table down_table strip_table'>
                                          <tbody>
                                            <WarrantyAttributesTable
                                              getprefer={
                                                this.props.getprefer &&
                                                  this.props.getprefer.customerView
                                                  ? this.props.getprefer.customerView
                                                  : 'c1'
                                              }
                                              product={product}
                                              mapRedirect={this.mapRedirect}
                                              isCustomerView={this.props.viewType === CUSTOMER_VIEW}
                                              multiStoreDetails={this.props.multiStoreDetails}
                                              access={this.props.multiStoreDetails.access}
                                              quantity={this.state.quantity}
                                          ></WarrantyAttributesTable>
                                        </tbody>
                                      </table>
                                    )
                                    : (
                                      <tbody />
                                    )}
                                  </div>
                                ) : ''}
                                {this.props.viewType === CUSTOMER_VIEW ? (
                                  <div className='productDetailSpecification'  ref={this.refOrderingSpec}
                                    colSpan={this.props.viewType === CUSTOMER_VIEW ? '10' : '4'}
                                  >
                                    <span className='productSpecSubTitle'>Pack Details</span>
                                    <span>
                                      <i className={classnames(
                                        `${this.state.showOrderingSpec ? 'minus' : 'plus'}`,
                                        'icon',
                                        'fl-r'
                                      )}
                                        onClick={() => this.toggleDiv('showOrderingSpec')}
                                      ></i>
                                    </span>
                                    {this.state.showOrderingSpec
                                      ? (
                                        <OrderingTable
                                          getprefer={
                                            this.props.getprefer &&
                                              this.props.getprefer.customerView
                                              ? this.props.getprefer.customerView
                                              : 'c1'
                                          }
                                          product={product}
                                          mapRedirect={this.mapRedirect}
                                          isCustomerView={this.props.viewType === CUSTOMER_VIEW}
                                          multiStoreDetails={this.props.multiStoreDetails}
                                          access={this.props.multiStoreDetails.access}
                                          quantity={this.state.quantity}
                                          viewType = {this.props.viewType}
                                      ></OrderingTable>
                                      )
                                      : (
                                        <tbody />
                                      )
                                    }
                                  </div>
                                ):''}
                                {this.props.viewType === CUSTOMER_VIEW ? (
                                  <div className='productDetailSpecification'  ref={this.refShipping} colSpan='6'>
                                    <span className='productSpecSubTitle'>Shipping</span>
                                    <span>
                                      <i
                                        className={classnames(
                                          `${this.state.showShipping ? 'minus' : 'plus'}`,
                                          'icon',
                                          'fl-r'
                                        )}
                                        onClick={() => this.toggleDiv('showShipping')}
                                      ></i>
                                    </span>
                                    {this.state.showShipping ? (
                                      <ShippingTable
                                        product={product}
                                        isCustomerView={this.props.viewType === CUSTOMER_VIEW}
                                      />
                                      ) : (
                                        <tbody />
                                    )}
                                  </div>
                                ):''} 
                              </>
                            ):''}
                          </div>
                        </div>
                    ): ''}
                    {this.props.viewType !== CUSTOMER_VIEW && (
                      <div className='ui stackable one column grid whiteback'
                        style={window.innerWidth < 859 ? {} : { padding: '0rem 6rem' }}
                      >
                        <div className='sixteen column' id='Detailed_specification'>
                          <div className='productSpecification' >
                              <span   ref={this.refWholesale}> WHOLESALE </span>
                              <span>
                                <i className={classnames(
                                  `${this.state.showWholesale ? 'minus' : 'plus'}`,
                                  'icon',
                                  'fl-r'
                                )}
                                  onClick={() => this.toggleDiv('showWholesale')}
                                ></i>
                              </span>
                          </div>
                          {this.state.showWholesale? 
                            (
                              <>
                                <div className='productDetailSpecification'  ref={this.refOrderingSpec}
                                  colSpan={this.props.viewType === CUSTOMER_VIEW ? '10' : '4'}
                                >
                                  <span className='productSpecSubTitle'>Ordering </span>
                                  <span>
                                    <i className={classnames(
                                      `${this.state.showOrderingSpec ? 'minus' : 'plus'}`,
                                      'icon',
                                      'fl-r'
                                    )}
                                      onClick={() => this.toggleDiv('showOrderingSpec')}
                                    ></i>
                                  </span>
                                  {this.state.showOrderingSpec ? 
                                    (
                                      <OrderingTable
                                        getprefer={
                                          this.props.getprefer &&
                                            this.props.getprefer.customerView
                                            ? this.props.getprefer.customerView
                                            : 'c1'
                                        }
                                        product={product}
                                        mapRedirect={this.mapRedirect}
                                        isCustomerView={this.props.viewType === CUSTOMER_VIEW}
                                        multiStoreDetails={this.props.multiStoreDetails}
                                        access={this.props.multiStoreDetails.access}
                                        quantity={this.state.quantity}
                                      ></OrderingTable>
                                    )
                                    : (
                                      <tbody />
                                    )
                                  }
                                </div>
                              
                                <div className='productDetailSpecification'  ref={this.refShipping} colSpan='6'>
                                  <span className='productSpecSubTitle'>Shipping</span>
                                  <span>
                                    <i
                                      className={classnames(
                                        `${this.state.showShipping ? 'minus' : 'plus'}`,
                                        'icon',
                                        'fl-r'
                                      )}
                                      onClick={() => this.toggleDiv('showShipping')}
                                    ></i>
                                  </span>
                                  {this.state.showShipping ? (
                                    <ShippingTable
                                      product={product}
                                      isCustomerView={this.props.viewType === CUSTOMER_VIEW}
                                    />
                                  ) : (
                                      <tbody />
                                  )}
                                </div>
                                {this.props.viewType !== CUSTOMER_VIEW && (
                                  <div className='ui stackable one column grid hide_display'
                                    style={window.innerWidth < 859 ? {} : { padding: '0rem 6rem' }
                                    }
                                  >
                                    <div className='sixteen wide column product_details'>
                                      <div className='productDetailSpecification' colSpan='4'>
                                        <span className='productSpecSubTitle'>
                                          Invoiced Orders {' '}
                                          {this.state.orderHistory &&
                                            this.state.orderHistory.length > 0
                                            ? '(last ' + this.state.orderHistory.length + ' orders)'
                                            : ''
                                          }
                                        </span>
                                        <span>
                                          <i className={classnames(
                                            `${this.state.showInvoicedOrders ? 'minus' : 'plus'}`,
                                            'icon',
                                            'fl-r'
                                          )}
                                            onClick={() => this.toggleDiv('showInvoicedOrders')}
                                          ></i>
                                        </span>
                                        {this.state.showInvoicedOrders ? (
                                          window.innerWidth >= 859 ? (
                                            <InvoicedOrdersTable
                                              orderHistory={this.state.invoicedOrders}
                                            />
                                          ) : (
                                              <InvoicedOrdersMobile
                                                orderHistory={this.state.invoicedOrders}
                                                toggleOrderCard={this.toggleOrderCard}
                                              />
                                            )
                                        ) : (
                                            <tbody />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {this.props.viewType !== CUSTOMER_VIEW && (
                                  <div className='ui stackable one column grid hide_display'
                                    style={window.innerWidth < 859 ? {} : { padding: '0rem 6rem' }
                                    }
                                  >
                                    <div className='sixteen wide column product_details'>
                                      <div className='productDetailSpecification' colSpan='4'>
                                        <span className='productSpecSubTitle'>
                                          Order History{' '}
                                          {this.state.orderHistory &&
                                            this.state.orderHistory.length > 0
                                            ? '(last ' + this.state.orderHistory.length + ' orders)'
                                            : ''
                                          }
                                        </span>
                                        <span>
                                          <i className={classnames(
                                            `${this.state.showorderHistory ? 'minus' : 'plus'}`,
                                            'icon',
                                            'fl-r'
                                          )}
                                            onClick={() => this.toggleDiv('showorderHistory')}
                                          ></i>
                                        </span>
                                        {this.state.showorderHistory ? (
                                          window.innerWidth >= 859 ? (
                                            <OrderHistoryTable
                                              orderHistory={this.state.orderHistory}
                                            />
                                          ) : (
                                            <OrderHistoryMobile
                                                orderHistory={this.state.orderHistory}
                                                toggleOrderCard={this.toggleOrderCard}
                                            />
                                          )
                                        ) : (
                                          <tbody />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            ):<></>
                          }
                      </div>
                      </div>
                    )} 

                    <div className='ui stackable one column grid whiteback'
                        style={window.innerWidth < 859 ? {} : { padding: '0rem 6rem' }}
                      >
                        <div className='sixteen column' id='Detailed_specification'>
                          <div className='productSpecification'>
                              <span  ref={this.refRestriction}> REGULATIONS AND RESTRICTIONS</span>
                              <span>
                                <i className={classnames(
                                  `${this.state.showRestriction ? 'minus' : 'plus'}`,
                                  'icon',
                                  'fl-r'
                                )}
                                  onClick={() => this.toggleDiv('showRestriction')}
                                ></i>
                            </span>
                          </div>
                          {this.state.showRestriction ? 
                            (
                              <>
                                <div className='onlineRestrication' 
                                  colSpan={this.props.viewType === CUSTOMER_VIEW ? '10' : '4'}
                                >
                                  <span className='productSpecSubTitle'> Selling
                                    <span className='link-style' onClick={() => this.toggleDiv('onlineRestrictionDialog')}>
                                      <i className='info circle icon'/> 
                                    </span> 
                                  </span>
                                  <span>
                                    <i className={classnames(
                                      `${this.state.onlineRestriction ? 'minus' : 'plus'}`,
                                      'icon',
                                      'fl-r'
                                    )}
                                      onClick={() => this.toggleDiv('onlineRestriction')}
                                    ></i>
                                  </span>
                                  {this.state.onlineRestriction
                                    ? (
                                      <SellingRestrictionTable
                                        isCustomerView={this.props.viewType === CUSTOMER_VIEW}
                                        product={product}
                                        mapRedirect={this.mapRedirect}
                                        ></SellingRestrictionTable>
                                    )
                                    : (
                                      <tbody />
                                    )
                                  }
                                </div>
                              </>):
                              <></>}
                          {this.state.onlineRestrictionDialog && <OnlineRestrictionDialog vendorPolicyRedirect={this.vendorPolicyRedirect} toggleDiv = {this.toggleDiv}/>}
                        </div>
                    </div>
                     

                    {this.props.viewType !== CUSTOMER_VIEW && (
                        <div className='ui stackable one column grid whiteback'
                          style={window.innerWidth < 859 ? {} : { padding: '0rem 6rem' }}
                        >
                          <div className='sixteen column' id='Detailed_specification'>
                            <div className='productSpecification'>
                                <span ref={this.refRetail}> RETAIL</span>
                                <span>
                                  <i className={classnames(
                                    `${this.state.showRetail ? 'minus' : 'plus'}`,
                                    'icon',
                                    'fl-r'
                                  )}
                                    onClick={() => this.toggleDiv('showRetail')}
                                  ></i>
                                </span>
                            </div>
                            {this.state.showRetail ? (
                            <>
                              <div className='productDetailSpecification'  colSpan='4'>
                                  <span className='productSpecSubTitle'>Planograms</span>
                                  <span>
                                    <i className={classnames(
                                      `${this.state.showPlanograms ? 'minus' : 'plus'}`,
                                      'icon',
                                      'fl-r'
                                    )}
                                      onClick={() => this.toggleDiv('showPlanograms')}
                                    ></i>
                                  </span>
                                  {this.state.showPlanograms ? (
                                    <PlanogramsTable planograms={product.PlanogramMessage} />
                                  ) : (
                                      <tbody />
                                    )
                                  }
                              </div>
                              <div className='productDetailSpecification hide_display'  ref={this.refRetailerQOH} colSpan='4'>
                                <span className='productSpecSubTitle'>Retailer On Hand Inventory</span>
                                <span>
                                  <i className={classnames(
                                    `${this.state.showOnHandInventory
                                      ? 'minus'
                                      : 'plus'
                                    }`,
                                    'icon',
                                    'fl-r'
                                  )}
                                    onClick={() => this.toggleDiv('showOnHandInventory')}
                                  ></i>
                                </span>
                                {this.state.showOnHandInventory ? (
                                  <OnHandInventoryTable
                                    retailOnHandInventory={product?.PosOnHandInventory}
                                  />
                                  ) : (
                                    <tbody />
                                )}
                              </div>
                              <div className='productDetailSpecification hide_display'  ref={this.refWarehouse} colSpan='4'>
                              <span className='productSpecSubTitle'>R12 warehouse purchases</span>
                              <span>
                                <i className={classnames(
                                  `${this.state.posSale
                                    ? 'minus'
                                    : 'plus'
                                  }`,
                                  'icon',
                                  'fl-r'
                                )}
                                  onClick={() => this.toggleDiv('posSale')}
                                ></i>
                              </span>
                              {this.state.posSale ? (
                                <PosSale
                                posSale={product?.PosSales}
                                />
                              ) : (
                                  <tbody />
                              )}
                            </div>
                            </>):<></>}
                          </div>
                        </div>
                    )}
                    {this.props.viewType === CUSTOMER_VIEW && (
                        <div className='ui stackable one column grid whiteback'
                          style={window.innerWidth < 859
                            ? {}
                            : { padding: '0rem 6rem' }
                          }
                        >
                          <div className='sixteen column' id='Detailed_specification'>
                            <div className='productSpecification' >
                              <span ref={this.refStoreInfo}> STORE INFORMATION </span>
                              <span>
                                <i className={classnames(
                                  `${this.state.showStoreInformation
                                    ? 'minus'
                                    : 'plus'
                                  }`,
                                  'icon',
                                  'fl-r'
                                )}
                                  onClick={() => this.toggleDiv('showStoreInformation')}
                                ></i>
                              </span>
                            </div>
                            <div className='productDetailSpecification'>
                                {this.state.showStoreInformation ? (
                                  <StoreInformationTable address={this.props.storeAddress} />
                                ) : (
                                    <tbody />
                                  )}
                            </div>
                          </div>
                        </div>
                    )}
                  </div>
                </>
                <br />
                <br />
              </div>
            </>
          )}
        </Segment>
        </>
         : ( (!isMonthlyListing && this.state.isAltPDPapiProduct === true && Object.keys(product).length) > 0 || (isMonthlyListing && !MSData && this.state.isAltPDPapiProduct === false ) )
            && <div className='no_prdcts_display'><h1>No Products to Display!</h1></div> 
        }
      </>
    )
  }
}

const checkDSCost = (DScost) => {
  DScost = utilities.emptyForNull(DScost);
    if(isNaN(DScost)){
      return DScost;
    }
    else{
      return '$'+DScost;
  }
}

const checkDataSource = (value, dataSource) => {
  // if (dataSource === 'WH') {
  //   return value;
  // } else if (dataSource === 'US' || dataSource === 'WT' || dataSource === 'AG' || dataSource === 'OS') {
  //   return '-';
  // } else {
  //   return '';
  // }
  return utilities.hyphenForNull(value);
};

const isTrueServDataSource = dataSource => {
  return dataSource === 'WH';
};

const ProductDimensionSpecTable = ({ product, mapRedirect, isCustomerView, getprefer, multiStoreDetails, access, quantity }) => {
  const { dataSource } = product;
  const pricing = product.pricing ? product.pricing : '';
  return (
    <table className='ui celled table down_table strip_table'>
      <tbody>
      <DimAttributesTable product={product}/>
      </tbody>
    </table>
  );
};

const DimAttributesTable = ({product}) => {
  const DimensionAttributes = [];
  const DimAttLen = product.DimensionAttributes ? Object.keys(product.DimensionAttributes).length : 0;
  for(var i=0;i<DimAttLen;i=i+2){
    var key1="",value1="",key2="",value2 = "";
    key1 = Object.keys(product.DimensionAttributes)[i];
    value1 = product.DimensionAttributes[key1];

    if(DimAttLen > i+1){
      key2 = Object.keys(product.DimensionAttributes)[i+1];
      value2 = product.DimensionAttributes[key2];
    }
    DimensionAttributes.push(
      <tr>
        <td>{key1}</td>
        <td>{value1}</td>
        <td>{key2}</td>
        <td>{value2}</td>
      </tr>
    )
  }

  return DimensionAttributes;

}

const DetailedSpecTable = ({ product, mapRedirect, isCustomerView, getprefer, multiStoreDetails, access, quantity }) => {
  const { dataSource } = product;
  const pricing = product.pricing ? product.pricing : '';
  
  return (
    <table className='ui celled table down_table strip_table'>
      <tbody>
        <FineLineAttributesTable product={product}/>
      </tbody>
    </table>
  );
};

const FineLineAttributesTable = ({product}) => {
  const FinelineAttributes = [];
  const FineLineAttLen = product.FinelineAttributes ? Object.keys(product.FinelineAttributes).length : 0;
  for(var i=0;i<FineLineAttLen;i=i+2){
    var key1="",value1="",key2="",value2 = "";
    key1 = Object.keys(product.FinelineAttributes)[i];
    value1 = product.FinelineAttributes[key1];

    if(FineLineAttLen > i+1){
      key2 = Object.keys(product.FinelineAttributes)[i+1];
      value2 = product.FinelineAttributes[key2];
    }
    FinelineAttributes.push(
      <tr>
        <td>{key1}</td>
        <td>{value1}</td>
        <td>{key2}</td>
        <td>{value2}</td>
      </tr>
    )
  }

  return FinelineAttributes;

}

const WarrantyAttributesTable = ({product}) => {
  const WarrantyAttributes = [];
  const WarrantyAttLen = product.WarrantyAttributes ? Object.keys(product.WarrantyAttributes).length : 0;
  for(var i=0;i<WarrantyAttLen;i=i+2){
    var key1="",value1="",key2="",value2 = "";
    key1 = Object.keys(product.WarrantyAttributes)[i];
    value1 = product.WarrantyAttributes[key1];

    if(WarrantyAttLen > i+1){
      key2 = Object.keys(product.WarrantyAttributes)[i+1];
      value2 = product.WarrantyAttributes[key2];
    }
    WarrantyAttributes.push(
      <tr>
        <td>{key1}</td>
        <td>{value1}</td>
        <td>{key2}</td>
        <td>{value2}</td>
      </tr>
    )
  }
    return WarrantyAttributes;

}

const OrderingTable = ({ product, mapRedirect, isCustomerView, getprefer, multiStoreDetails, access, quantity,viewType }) => {
  const { dataSource } = product;
  const pricing = product.pricing ? product.pricing : '';
  const showMemberCostAndLbl = shouldHideCostForPreference(viewType ,getprefer);

  return (
    <table className='ui celled table down_table strip_table'>
      {!isCustomerView ? (
      <tbody>
        <tr>
          <td>Retail Fine Line Code</td>
          <td>{checkDataSource(product.RetailFineLineCode, dataSource)}</td>
          <td>Primary UPC #</td>
          <td>{product.UpcNum && utilities.padWithZeros(product.UpcNum, 14)}</td>
         
        </tr>
        <tr>
          <td>Each Length (in.)</td>
          <td>{product.Length}</td>
          <td>Secondary UPC #</td>
          <td>{product.secUpc && utilities.multiUpcFormat(product.secUpc)}</td>
          
        </tr>
        <tr>
          <td>Each Width (in.)</td>
          <td>{product.Width}</td> 
          <td>Inner Pack</td>
          <td>{checkDataSource(product.InnerPack, dataSource)}</td>
        </tr>     
        <tr>
          <td>Each Height (in.)</td>
          <td>{product.Height}</td>
          <td>Master Pack</td>
          <td>{checkDataSource(product.MasterPack, dataSource)}</td>
        </tr>
        <tr>
          <td>Each Weight (lb.)</td>
          <td>{product.weight}</td>
          <td>Pallet Pack</td>
          <td>
                  {product.PalletQuantity
                    ? checkDataSource(product.PalletQuantity, dataSource)
                    : 0}
          </td>
        </tr>
        <tr>
          <td>Policy A</td>
          <td>{checkDataSource(product.Policy_A,product.dataSource)}</td>
          <td>Future Promotion Numbers</td>
          <td>{checkDataSource(product.FuturePromotionalNumbers, dataSource)}</td>
        </tr>
      </tbody>
      ):(
        <tbody>
          <tr>
            <td>Each Length (in.)</td>
            <td>{product.Length}</td>
            <td>Each Width (in.)</td>
            <td>{product.Width}</td> 
          </tr>
          <tr>
            <td>Each Height (in.)</td>
            <td>{product.Height}</td>
            <td>Each Weight (lb.)</td>
            <td>{product.weight}</td>
          </tr> 
          <tr>
            <td>Primary UPC #</td>
            <td>{product.UpcNum && utilities.padWithZeros(product.UpcNum, 14)}</td>
            {showMemberCostAndLbl && <>
           <td>{utilities.getLabelsByType(viewType,"MEMBER_COST")}
          </td> 
            <td>
              {(access !== 1 || (access === 1 && multiStoreDetails.CatMbr)) &&
                <MaskPrice
                  getprefer={getprefer}
                  viewType={CUSTOMER_VIEW}
                  fieldVal={product?.pricing?.tiered ? utilities.getCostForQtyPlp(pricing, quantity || '', false) : product?.pricing?.Member_Cost}
                  field='Member Cost'
                />
              }
            </td>
            </>}
          </tr>
        </tbody>
      )}
    </table>
  );
};

const ShippingTable = ({ product, isCustomerView }) => {
  const { dataSource } = product;
  return (
    <table className='ui celled table down_table strip_table'>
      {!isCustomerView ? (
      <tbody>
        <tr>
          <td className='bold-col-header'>Haz Mat</td>
          <td className='bold-col-header'>
                    {checkDataSource(
                      product.HazMat === 'N' ? 'No' : 'Yes',
                      dataSource
                    )}
          </td>
          <td>Heat Sensitive</td>
          <td>{checkDataSource(product.HEAT_SENSITIVE, dataSource)}</td>
        </tr>
        <tr>
          <td>Air Transport</td>
          <td>
            {product.AirTransport
            ? checkDataSource(product.AirTransport, dataSource)
            : 'Unknown'}
          </td>
          <td>Perishable</td>
          <td>
              {product.PERISHABLE
                ? checkDataSource(
                  product.PERISHABLE === 'N' ? 'No' : 'Yes',
                  dataSource
                )
                : 'Unknown'}
          </td>
        </tr>
        <tr>
          <td>Water Transport</td>
          <td>{checkDataSource(product.WaterTransport, dataSource)} </td>
          <td>Freezeable</td>
          <td>{checkDataSource(product.FREEZEABLE, dataSource)}</td> 
        </tr>
        <tr>
          <td className='store-name'>
            <b>Small Parcel</b>
          </td>
          <td className='store-name-aw'>
            <b>
              {isTrueServDataSource(dataSource) &&
                utilities.emptyForNull(product.FedEx)}
            </b>
          </td>
          <td>Schedule B</td>
          <td>{checkDataSource(
                    utilities.hyphenForNull(product.ScheduleB),
                    dataSource
                  )}
          </td>
        </tr>
        <tr>
          <td>Country of Origin</td>
          <td>{checkDataSource(product.CountryCode, dataSource)}</td>
          <td>ECCN</td>
          <td>
                  {isTrueServDataSource(dataSource) &&
                    utilities.hyphenForNull(product.eccnCD)}
          </td>  
        </tr>
      </tbody>
      ) :
      (
      <tbody>
         <tr>
           <td className='store-name'>
             <b>Small Parcel</b>
           </td>
           <td className='store-name-aw'>
             <b>
               {isTrueServDataSource(dataSource) &&
                 utilities.emptyForNull(product.FedEx)}
             </b>
           </td>
           <td></td>
           <td></td>
         </tr>
      </tbody>
    )}
  </table>
  );
};

const OrderHistoryMobile = ({ orderHistory, toggleOrderCard }) => {
  return (
    <div className='order-history-mobile'>
      {orderHistory &&
        orderHistory.map((order, index) => {
          return (
            <div className='invoice-card'>
              <div className='invoice-header'
                onClick={() => toggleOrderCard(index)}
              >
                <b>
                  Invoice#:{' '}
                  {utilities.hyphenForNull(order.displayedInvoiceNumber)}
                </b>
                <span>
                  {' '}
                  Qty: {utilities.hyphenForNull(order.numberOfItems)}
                </span>
                <span>Type: {utilities.hyphenForNull(order.orderType)}</span>
                <i className='angle down icon toggleIcon'></i>
              </div>
              <div className='invoice-body' id={'invoice-body' + index}>
                <p>Date : {utilities.hyphenForNull(order.invoiceDateString)}</p>
                <p>Status : {utilities.hyphenForNull(order.displayedStatus)}</p>
                <p>Release Week : {utilities.hyphenForNull(order.retailerUpdateDateString)}</p>
                <p>PO# : {utilities.hyphenForNull(order.memberOrderRef)}</p>
                <p>Event : {utilities.hyphenForNull(order.eventNumber)}</p>
              </div>
            </div>
          );
        })}
    </div>
  );
};
const InvoicedOrdersMobile = ({ orderHistory, toggleOrderCard }) => {
  return (
    <div className='order-history-mobile'>
      {orderHistory &&
        orderHistory.map((order, index) => {
          return (
            <div className='invoice-card'>
              <div className='invoice-header'
                onClick={() => toggleOrderCard(index,true)}
              >
                <b>
                  Invoice#:{' '}
                  {utilities.hyphenForNull(order.displayedInvoiceNumber)}
                </b>
                <span>
                  {' '}
                  Qty: {utilities.hyphenForNull(order.numberOfItems)}
                </span>
                <span>Type: {utilities.hyphenForNull(order.orderType)}</span>
                <i className='angle down icon toggleIcon'></i>
              </div>
              <div className='invoice-body' id={'order-body' + index}>
                <p>Date : {utilities.hyphenForNull(order.invoiceDateString)}</p>
                <p>Status : {utilities.hyphenForNull(order.displayedStatus)}</p>
                <p>Release Week : {utilities.hyphenForNull(order.retailerUpdateDateString)}</p>
                <p>PO# : {utilities.hyphenForNull(order.memberOrderRef)}</p>
                <p>Event : {utilities.hyphenForNull(order.eventNumber)}</p>
              </div>
            </div>
          );
        })}
    </div>
  );
};

const OrderHistoryTable = ({ orderHistory }) => {
  return (
    <>
      {orderHistory && orderHistory.length === 0 ? (
        <p className='t-center down_table'>No order history found!</p>
      ) : (
          <table className='ui celled table down_table'
            id='order_table'
            colSpan='1'
          >
            <thead>
              <tr colSpan='2'>
                <th className='order_header'>Invoice #</th>
                <th className='order_header'>QTY</th>
                <th className='order_header'>DATE</th>
                <th className='order_header'>TYPE</th>
                <th className='order_header'>STATUS</th>
                <th className='order_header'>PO #</th>
                <th className='order_header'>Expected Release Week</th>
                <th className='order_header'>Event #</th>
              </tr>
            </thead>
            <tbody>
              {orderHistory &&
                orderHistory.map(order => {
                  return (
                    <tr>
                      <td>{utilities.hyphenForNull(order.displayedInvoiceNumber)}</td>
                      <td>{utilities.hyphenForNull(order.itemQuantity)}</td>
                      <td>{utilities.hyphenForNull(order.invoiceDateString)}</td>
                      <td>{utilities.hyphenForNull(order.orderType)}</td>
                      <td>{utilities.hyphenForNull(order.displayedStatus)}</td>
                      <td>{utilities.hyphenForNull(order.memberOrderRef)}</td>
                      <td>{utilities.hyphenForNull(order.retailerUpdateDateString)}</td>
                      <td>{utilities.hyphenForNull(order.eventNumber)}</td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        )}
    </>
  );
};
const InvoicedOrdersTable = ({ orderHistory }) => {
  return (
    <>
      {orderHistory && orderHistory.length === 0 ? (
        <p className='t-center down_table'>No order history found!</p>
      ) : (
          <table className='ui celled table down_table'
            id='order_table'
            colSpan='1'
          >
            <thead>
              <tr colSpan='2'>
                <th className='order_header'>Invoice #</th>
                <th className='order_header'>QTY</th>
                <th className='order_header'>DATE</th>
                <th className='order_header'>TYPE</th>
                <th className='order_header'>STATUS</th>
                <th className='order_header'>PO #</th>
                <th className='order_header'>Expected Release Week</th>
                <th className='order_header'>Event #</th>
              </tr>
            </thead>
            <tbody>
              {orderHistory &&
                orderHistory.map(order => {
                  return (
                    <tr>
                      <td>{utilities.hyphenForNull(order.displayedInvoiceNumber)}</td>
                      <td>{utilities.hyphenForNull(order.itemQuantity)}</td>
                      <td>{utilities.hyphenForNull(order.invoiceDateString)}</td>
                      <td>{utilities.hyphenForNull(order.orderType)}</td>
                      <td>{utilities.hyphenForNull(order.displayedStatus)}</td>
                      <td>{utilities.hyphenForNull(order.memberOrderRef)}</td>
                      <td>{utilities.hyphenForNull(order.retailerUpdateDateString)}</td>
                      <td>{utilities.hyphenForNull(order.eventNumber)}</td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        )}
    </>
  );
};

const SellingRestrictionTable = ({product,isCustomerView,mapRedirect}) => {
  const { dataSource } = product;
  let data = {
    AOSRFlag: 'N',
    TPWRFlag: 'N',
    STPWRFlag: 'N',
    AOROWFlag: 'Y',
    AOLEWFlag: 'Y'
  }
  if((_.isEmpty(product.TPR_CD)) && (_.isEmpty(product.TSR_CD))){
    data = data
  }else if ((!(_.isEmpty(product.TPR_CD))) && (_.isEmpty(product.TSR_CD))){
      if ((_.isEmpty(product.ARO_CD)) && (_.isEmpty(product.ALE_CD))){
        data = {
        AOSRFlag : "Y",
        TPWRFlag : "Y",
        STPWRFlag : "Y",
        AOROWFlag : "N",
        AOLEWFlag : "N"		
        }
    } else if ((!(_.isEmpty(product.ARO_CD))) && (_.isEmpty(product.ALE_CD))){
      data = {
      AOSRFlag : "N",
      TPWRFlag : "Y",
      STPWRFlag : "Y",
      AOROWFlag : "Y",
      AOLEWFlag : "N",
      }
    } else if ((!(_.isEmpty(product.ALE_CD))) && (_.isEmpty(product.ARO_CD))){
      data = {
      AOSRFlag : "N",
      TPWRFlag : "Y",
      STPWRFlag : "Y",
      AOROWFlag : "N",
      AOLEWFlag : "Y"
      }
    } else{
      data = {
      AOSRFlag : "N",
      TPWRFlag : "Y",
      STPWRFlag : "Y",
      AOROWFlag : "Y",
      AOLEWFlag : "Y"
      }
    } 
  } else if ((!(_.isEmpty(product.TPR_CD))) && (!(_.isEmpty(product.TSR_CD)))){
    data.AOSRFlag = "N";
    data.TPWRFlag = "N";
    data.STPWRFlag = "Y";
    if (_.isEmpty(product.ARO_CD)){
      data.dataAOROWFlag = "N";
    } else{
      data.AOROWFlag = "Y";
    }
    if (_.isEmpty(product.ALE_CD)){
      data.AOLEWFlag = "N";
    } else{
      data.AOLEWFlag = "Y";
    } 
  }
  return(
    <table className='ui celled table down_table strip_table'>
    {!isCustomerView ? (
      <tbody>
        <tr>
          {utilities.trueForZero(product.MAPP) && (
            <>
              <td className='bold-col-header'>MAPP</td>
              <td className='bold-col-header'>
                {isTrueServDataSource(dataSource) && product.MAPP > 0
                            ? <span>
                              ${product.MAPP}
                                <span className='link-style' onClick={mapRedirect}>
                                  <i title={MAP_REDIRECTION_URL} className='info circle icon'/> 
                                </span>
                              </span>
                            : ''}
              </td>
            </>
          )}
          <td>All Online Sales Restricted</td><td className="col_widthVal"><span> {data.AOSRFlag}</span></td>
        </tr>
        <tr>
          {utilities.trueForZero(product.IMAPP) && (
            <>
              <td className='bold-col-header'>IMAPP</td>
              <td className='bold-col-header'>
                          {isTrueServDataSource(dataSource) && product.IMAPP > 0
                            ? <span>
                                ${product.IMAPP}`
                                <span className='link-style' onClick={mapRedirect}>
                                  <i title={MAP_REDIRECTION_URL} className='info circle icon'/> 
                                </span>
                              </span>
                            : ''}
              </td>
            </>
          )}
          <td>All 3rd Party Websites Restricted</td><td className="col_widthVal"><span> {data.TPWRFlag}</span></td>
        </tr>
        <tr>
          <td>Allowed on Retailer Owned Website</td><td className="col_widthVal"><span> {data.AOROWFlag}</span></td>
          <td>Specific 3rd Party Websites Restricted</td><td className="col_widthVal"><span> {data.STPWRFlag}</span></td>
        </tr>
        <tr>
          <td>Allowed on Local Ecommerce Website</td><td className="col_widthVal"><span> {data.AOLEWFlag}</span></td>
          <td></td><td></td>
        </tr>
      </tbody>
    ): (
      <tbody>
        <tr>
          <td>All Online Sales Restricted</td><td className="col_widthVal"><span> {data.AOSRFlag}</span></td>
          <td>All 3rd Party Websites Restricted</td><td className="col_widthVal"><span> {data.TPWRFlag}</span></td>
        </tr>
      
        <tr>
          <td>Allowed on Retailer Owned Website</td><td className="col_widthVal"><span> {data.AOROWFlag}</span></td>
          <td>Specific 3rd Party Websites Restricted</td><td className="col_widthVal"><span> {data.STPWRFlag}</span></td>
        </tr>
        <tr>
          <td>Allowed on Local Ecommerce Website</td><td className="col_widthVal"><span> {data.AOLEWFlag}</span></td>
          <td></td><td></td>
        </tr>
      </tbody>
    )}
  </table>
  )
}

const OnHandInventoryTable= ({ retailOnHandInventory }) => {
  return (
    <table className='ui celled table down_table strip_table'>
      <tbody>
        <tr>
          <td>
            Quantity
          </td>
          <td>
          { utilities.trueForZero(retailOnHandInventory) ? retailOnHandInventory : ' Data not shared'}
          </td>
          <td>
            </td>
            <td>
            </td>
        </tr>
      </tbody>
    </table>
  );
};
const PosSale = ({ posSale }) => {
  const totalPosSale = posSale && posSale.reduce( ( sum, { Sales } ) => sum + Sales , 0)
  return (
    posSale ?
    <table className='ui celled table down_table posTable'>
      <thead>
        <tr>
        {posSale &&  posSale.map(pos => <th>{pos.FY}<p>{pos.Period}</p></th> )}
        <th>R12<p>Total</p></th>
        </tr>
      </thead>
      <tbody>
        <tr>
        {posSale.map(pos => <td>{pos.Sales}</td> )}
        <td>{totalPosSale}</td>
        </tr>
      </tbody>
    </table>
    : null
  )
}

const StoreInformationTable = ({ address }) => {
  return (
    <table className='ui celled table down_table strip_table'>
      <tbody>
        <tr className='bold-row'>
          <td className='store-name'>
            <b>Store Name</b>
          </td>
          <td>
            <b>{address.storeName}</b>
          </td>
          <td className='color-red' style={{width: '50%'}}>
            <Link to='#' className='ui tiny image' id='img'>
              {renderImages.getImage({
                src: tv_logo,
                style: { maxWidth: '100%' }
              })}
            </Link>
          </td>
        </tr>
        <tr className='bold-row'>
          <td>
            <b>Location</b>
          </td>
          <td>
            <b>
              {address.addressLine1 + ', ' + address.addressLine2 + ', ' + address.City}
            </b>
          </td>
          <td>
            <b>Notes</b>
          </td>
        </tr>
        <tr />
        <tr className='bold-row'>
          <td>
            <b>Phone</b>
          </td>
          <td>
            <b>{address.phoneNumber}</b>
          </td>
          <td rowSpan='4'>
            <textarea className='height-4'></textarea>
          </td>
        </tr>
        <tr className='bold-row'>
          <td>
            <b>Fax</b>
          </td>
          <td>
            <b>{address.faxNumber}</b>
          </td>
        </tr>
        <tr className='bold-row'>
          <td>
            <b>Email</b>
          </td>
          <td>
            <b>{address.primaryEmail}</b>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
const OnlineRestrictionDialog = ({vendorPolicyRedirect, toggleDiv}) => {
  return(
    <div id="onlineResDialog">
    <Dialog width='1' height='1' position='absolute'>
      <div className='online_dialog_title'>
        <h3>Restriction Help Verbiage:</h3>
        <span className='online_dialog_close' onClick={() => toggleDiv('onlineRestrictionDialog')}>X</span>
      </div>
      <div className="online_dialogContent">
      <p><strong>Online Selling Restriction -</strong> Item cannot be sold on any online platform (e.g. Retailer’s own website or local eCommerce sites offered by True Value), or on any 3rd party marketplace websites.</p>
      <p><strong>All 3rd Party Marketplace Websites Restricted -</strong>  Item cannot be sold on any 3rd party marketplace websites (can sell on your Retailer owned or operated website).</p>
      <p><strong>Specific 3rd Party Marketplace Websites Restricted - </strong>
      Item cannot be sold on specific 3rd party marketplace websites. For list of restricted websites consult the product vendor’s policy posted on <a onClick={vendorPolicyRedirect}>MOL</a>.</p>
      <p><strong>Allowed on Retailer Owned Website -</strong>Item can be sold on Retailer’s website, whether owned, hosted and maintained by the Retailer or through a Web Hosting service.</p>
      <p><strong>Allowed on Local eCommerce Site - </strong>
      Item can be sold on local eCommerce sites (offered by TV). </p>
      </div>
    </Dialog>
    </div>
  )
}
const mapStateToProps = state => {
  const carts = state.cart.orders.allCarts;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    currentCartId: state.cart.orders.currentCart.cartId,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    favorites: state.FavoritesReducer.favItemNums,
    carts,
    storeAddress: state.SessionReducer.address,
    storeId: state.SessionReducer.storeId,
    userId: state.SessionReducer.UserId,
    rdc: state.SessionReducer.address ? state.SessionReducer.address.rdc : '',
    currentCartType: state.cart.orders.currentCart.cartInfoName,
    whCarts: carts.filter(cart => cart.cartInfoName === 'REGULAR'),
    getprefer: state.preference.listData,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    groupCollectionId: state.SessionReducer.address ? state.SessionReducer.address.groupCollectionId : '',
    aliasSKURetailerGrpID:state.SessionReducer.address ? state.SessionReducer.address.aliasSKURetailerGrpID : '',
    onVariablePricing: state.SessionReducer.multiStoreDetails ? state.SessionReducer.multiStoreDetails.onVariablePricing : false,
    isReunionOnlyStore: state.SessionReducer.address.isReunionOnlyStore
  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({
      addToFavorites,
      removeFromFavorites,
      getFavoriteItems,
      addProduct,
      createNAddCart,
      addReunionCartProduct,
      chooseCart,
      addMonthlySpecialCart,
      focusChange
    },
      dispatch
    )
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
