import { combineReducers } from 'redux';
import _ from 'lodash';
import {
  ADD_PRODUCT,
  CHECKOUT,
  SET_QUANTITY,
  REMOVE_PRODUCT,
  ADD_MANY_PRODUCTS,
  CREATE_CART,
  VIEW_ALL_CARTS,
  NOTIFY_CART,
  CHOOSE_CART,
  PLACE_ORDER,
  UPDATE_QUANTITY,
  UPDATE_CART_NAME,
  DELETE_CART,
  UPDATE_SHIP_TO,
  ERROR,
  MARKET_ENABLE,
  IS_FETCHING_DATA,
  REUNION_ORDERING_ENABLE,
  MS_WHDD_PRODUCT,
  UPDATE_QTY,
  UPDATE_TOTALCOST,
  GET_VIEW_ALL_CARTS,
  SET_CURRENT_CART,
  UPDATE_DIRECTSHIP_CARTDETAILS,
  GETSTATEJSON
} from './actions';
import utilities from '../../utils/utilities';

const initState = {
  currentCart: {},
  currentOrderType: 'REGULAR',
  allCarts: [],
  notifyUser : false,
  error: false,
  addressList: [],
  directShipCartDetails: {}
};
const itemReducer = (item1, item2) =>
  parseFloat(item1) + parseFloat(item2.qty ? item2.qty : item2.itemQty ) * parseFloat(item2.cost);
export const promoItemReducer = (item1, item2) =>
parseFloat(item1) + parseFloat(item2.qty ? item2.qty : item2.itemQty ) * parseFloat(item2.promoCost);

const orders = (state = initState, { type, payload }) => {
  switch (type) {
    case IS_FETCHING_DATA:
      return {...state,isFetchingData:payload};
    case CHOOSE_CART:
      return { ...state, currentCart: payload };
    case ADD_PRODUCT:
      let allCartsNew = state.allCarts;
      let itemExists = false;
      let currentCartNew = state.currentCart;
      allCartsNew = allCartsNew.map(cart => {
        if (parseInt(cart.cartId) === parseInt(payload.cartId)) {
          cart.items = cart.items.map(item => {
            if(payload?.isDirectShip &&  parseInt(item.item_nbr) === parseInt(payload.itemno)) {
              itemExists = true;
              item.itemQty = parseInt(payload.qty);
            }
            if (
              parseInt(item.item_nbr) === parseInt(payload.itemno) &&
              parseInt(item.rdcNum) === parseInt(payload.rdcNum)
            ) {
              itemExists = true;
              item.itemQty = parseInt(item.itemQty) + parseInt(payload.qty);
            }
            return item;
          });
          if (!itemExists){
            const {itemQty,qty,userQty,userQty2,userQty3,userQty4} = payload;
            cart.items = [
              ...cart.items,
              {
                item_nbr: payload.itemno,
                itemQty: utilities.emptyForNull(itemQty?itemQty:qty),
                userQty,
                userQty2,
                userQty3,
                userQty4,
                or: payload.or ? payload.or : '',
                overrideRetail: payload.or ? payload.or : '',
                member_cost: payload.cost,
                cost:payload.cost,
                qty:utilities.emptyForNull(itemQty?itemQty:qty),
                rdcNum: payload.rdcNum
              }
            ];
          }
          cart.totalCost = ( cart.TypeId ===2)
          ?parseFloat(
            cart.items.reduce(promoItemReducer,0)
          ).toFixed(2)
          :parseFloat(
            cart.items.reduce(itemReducer, 0)
          ).toFixed(2);
          cart.cartCount = cart.items.length;
          currentCartNew.cartCount =
            parseInt(cart.cartId) === parseInt(currentCartNew.cartId)
              ? cart.cartCount
              : currentCartNew.cartCount;
        }
        return cart;
        // }
      });
      return {
        ...state,
        currentCart: currentCartNew,
        allCarts: allCartsNew,
        error: false
      };
      case UPDATE_DIRECTSHIP_CARTDETAILS:
        return {
          ...state,
          directShipCartDetails: {
            TotalCartItems : payload.TotalCartItems,
            TotalCartQty: payload.TotalCartQty,
            TotalCartValue: payload.TotalCartValue,
          }
        }
      case MS_WHDD_PRODUCT:
         allCartsNew = state.allCarts;
         itemExists = false;
         let currentCartExist=false;
         currentCartNew = state.currentCart;
         let newCartListMs =[]
         newCartListMs = allCartsNew.map(cart => {
          payload && payload.items && payload.items.map(payloadData=>{
              if (parseInt(payload.cartid) === parseInt(payloadData.CartId || payloadData.cartId)) {
                currentCartExist=true;
                if (parseInt(cart.cartId) === parseInt(payloadData.CartId || payloadData.cartId)) {
                  cart.items = cart.items.map(item => {
                    if (
                      parseInt(item.item_nbr) === parseInt(payloadData.ItemNumber) &&
                      parseInt(item.rdcNum) === parseInt(payloadData.RDC)
                    ) {
                      itemExists = true;
                    }
                    return item;
                  });
                  if (!itemExists){
                    cart.items = [
                      ...cart.items,
                      {
                        item_nbr: payloadData.ItemNumber,
                        rdcNum: payloadData.RDC
                      }
                    ];
                  }
                  if(parseInt(state.currentCart.cartId) !== parseInt(payloadData.CartId || payloadData.cartId)){
                    currentCartNew= {
                      cartCount: cart.items.length,
                      cartId:payload.cartid,
                      cartInfoName: payload.cartInfo,
                      orderType: payload.cartInfo,
                      TypeId: payload.typeInfo,
                      totalCost:parseFloat(
                                  cart?.items.reduce(itemReducer, 0)
                                ).toFixed(2)
                    };
                    
                  }
                  cart.cartCount = cart.items.length;
                  currentCartNew.cartCount =
                    parseInt(cart.cartId) === parseInt(currentCartNew.cartId)
                      ? cart.cartCount
                      : currentCartNew.cartCount;
                  cart.totalCost = parseFloat(
                                    payload?.items.reduce(itemReducer, 0)
                                  ).toFixed(2)
                }
              } 
            })
          return cart;
        });
        if(!currentCartExist){
          currentCartNew={
            cartId: payload.cartid,
            DisplayName: payload.cartInfo,
            cartCount: payload?.items?.length? payload?.items?.length:1,
            cartName: payload.cartName,
            items: payload.items?payload.items : [],
            cartInfoName: payload.cartInfo,
            orderType: payload.cartInfo,
            TypeId: payload.typeInfo,
            totalCost:parseFloat(
                        payload?.items.reduce(itemReducer, 0)
                      ).toFixed(2)
          }
          newCartListMs.push(currentCartNew);
        }

        return {
          ...state,
          currentCart: currentCartNew,
          allCarts: newCartListMs,
          error: false
        };
    case ERROR:
      return { ...state, error: true };
    case ADD_MANY_PRODUCTS:
      let { currentCart: newCurrentCart, allCarts: allStateCart = [] } = state;
      const {
        cartId,
        cartName = 'Planogram',
        orderType = (cartName === 'Assortment' || cartName==='CTBAssort')? 'Assortment' : 'Planogram',
        shipTo = 'Store',
        TypeId =cartName === 'Assortment' ? 4 :cartName==='CTBAssort' ? 44 : 5,
        items
      } = payload;

      let newCartList = [];
      if (allStateCart.length) {
        newCartList = allStateCart.map(cart => {
          if (parseInt(cart.cartId) === parseInt(cartId)) {
            if(cartName === 'Assortment' || cart.cartInfoName === 'Planogram' || cartName==='CTBAssort'){
              cart.items = [...items];
            }
            else{
              cart.items = [...cart.items, ...items];
            }
            cart.totalCost = parseFloat(
              cart.items.reduce(itemReducer, 0)
            ).toFixed(2);
            cart.cartCount = new Set(
              cart.items.map(item => {
                if (item.item_nbr) {
                  return `${item.item_nbr}-${item.rdcNum}`;
                }
                return `${item.itemno}-${item.rdcNum}`;
              })
            ).size;
            if(cartName==="CTBAssort") {
              cart.cartCount = cart.items.length;
            }
          }
          return cart;
        });
      }

      const currentCArtFromList = newCartList.find(
        cart => parseInt(cart.cartId) === parseInt(cartId)
      );

      // If there is no cart and user adds planogram to cart
      if (_.isEmpty(newCurrentCart) || !currentCArtFromList) {
        newCurrentCart = {
          cartCount: items.length,
          cartId,
          cartInfoName: orderType,
          cartName,
          DisplayName: cartName,
          items,
          orderType,
          shipTo,
          totalCost: parseFloat(items.reduce(itemReducer, 0)).toFixed(2),
          TypeId
        };
        newCartList.push(newCurrentCart);
      } else {
        newCurrentCart = currentCArtFromList;
      }

      return {
        ...state,
        currentCart: { ...newCurrentCart },
        allCarts: newCartList
      };

    case CHECKOUT:
      let updateCartsWithDetails = state.allCarts.map(cart => {
        if (parseInt(cart.cartId) === parseInt(payload.cartId)) {
          return { ...cart, checkoutDetails: payload.checkoutDetails };
        }
        return cart;
      });
      return { ...state, allCarts: updateCartsWithDetails };

    case VIEW_ALL_CARTS:
      let currentCart = state.currentCart;
      let currentCartExists = null ;
      const viewAllCarts = payload.carts;
      if (!payload.setCurrentCart && (_.isEmpty(currentCart) && viewAllCarts.length > 0)) {
        currentCart = viewAllCarts[0];
      } else {
        const selectedCartId = payload.setCurrentCart?payload.setCurrentCart:currentCart.cartId
        currentCartExists = viewAllCarts.find(
          cart => parseInt(cart.cartId) === parseInt(selectedCartId)
        );
        if (!currentCartExists && viewAllCarts.length > 0) {
          currentCart = viewAllCarts[0]; // handling case where user switches between 2 accounts or another session deletes current cart
        }
      }
      return { ...state, isFetchingData:false, currentCart:payload.setCurrentCart?currentCartExists:currentCart, allCarts: viewAllCarts };    
      
      case GET_VIEW_ALL_CARTS:
        const updateCartDetails = payload.carts.map(cart => cart.cartId)
        let updateCart = state.allCarts.map(cart => {
          if(updateCartDetails.includes(cart.cartId)) {
            cart = payload.carts.find(item=> item.cartId === cart.cartId)
          }
          return cart
        })
       return { ...state, isFetchingData:false, allCarts: updateCart };
    
      case UPDATE_QUANTITY:
      const attributeName= [
        "ItemQty",
        "ItemQty2",
        "ItemQty3",
        "ItemQty4"
      ]
      let qtyKey = 'itemQty'
      let qtyName = 'ItemQty' 
      let userQty = {}
      if(payload.wh === 'N') {
        let names = Object.keys(payload)
        qtyName = names.find(ele =>attributeName.includes(ele) &&  ele )
        qtyKey = parseInt(attributeName.indexOf(qtyName)) > 0 ? 'userQty'+(attributeName.indexOf(qtyName)+1) : "userQty"
        if(qtyKey === 'userQty') {
          userQty = {
            itemQty: parseInt(payload[qtyName])
          }
        }
      }
      else {
        userQty = {
          userQty: parseInt(payload[qtyName])
        }
      }     
      let allCarts = state.allCarts.map(cart => {
        if (parseInt(cart.cartId) === parseInt(payload.cartId)) {
          if(payload?.updateQty) {
          cart.items = cart.items.map(item => {
            item.item_nbr = item.item_nbr === 0 ? item.number : item.item_nbr
            const itemNumber = item.item_nbr !==0 ? item.item_nbr : item.number  !==0 ? item.number : item.model
            if(!_.isEmpty(payload.month)) { 
              return !(item.item_nbr === payload.itemno && item.month === payload.month) 
                      ? item 
                      : {
                        ...item,
                        [qtyKey]: parseInt(payload[qtyName]),
                        ...userQty,                  
                       };
            }
            return itemNumber !== payload.itemno 
              ? item
              : {
                  ...item,
                  [qtyKey]: parseInt(payload[qtyName]),
                  ...userQty,
                  overrideRetail: payload.or,
                  
                };
          });
        }
        else {
          cart.items = cart.items.map(item => {
            return item.item_nbr !== payload.itemno
              ? item
              : {
                  ...item,
                  overrideRetail: payload.or
                };
          });
        }
          cart.totalCost = (cart.TypeId ===2)
                            ?parseFloat(
                                cart.items.reduce(promoItemReducer,0)
                              ).toFixed(2)
                            :parseFloat(
                              cart.items.reduce(
                                (item1, item2) =>
                                  parseFloat(item1) +
                                  parseFloat(item2.itemQty) * parseFloat(item2.member_cost),
                                0
                              )
                            ).toFixed(2);
        }
        return cart;
      });
      return { ...state, allCarts };
    case UPDATE_TOTALCOST:
      let updatedTotalCost = state.allCarts.map(cart => {
        if (parseInt(cart.cartId) === parseInt(payload.cartId)) {
            return {
              ...cart,
              totalCost: payload.totalCost
            }
        }
        return cart
      })
      return { ...state, allCarts:updatedTotalCost };
    case SET_CURRENT_CART :
      return {...state, currentCart:payload.cart}
    case CREATE_CART:
      const newCart = {
        cartId: payload.cartId,
        cartName: payload.cartName,
        po: payload.poNum,
        shipTo: 'Store',
        items: [],
        cartInfoName: payload.orderType,
        orderType: payload.orderType,
        total: '',
        cartCount: 0,
        TypeId: payload.TypeId,
        VendorId:payload?.VendorId ? payload?.VendorId: ''
      };
      return {
        ...state,
        currentCart: newCart,
        allCarts: [...state.allCarts, newCart]
      };
    case UPDATE_CART_NAME:
      let currentCartinStore = state.currentCart;
      let carts = state.allCarts.map(cart => {
        if (parseInt(cart.cartId) === parseInt(payload.cartId)) {
          if(parseInt(currentCartinStore.cartId) === parseInt(payload.cartId)){
            currentCartinStore.cartName = payload.cartName;
          }
          return { ...cart, cartName: payload.cartName };
        }
        return cart;
      });
      return { ...state,currentCart:currentCartinStore, allCarts: carts };
    case UPDATE_SHIP_TO:
      let updatedCartList = state.allCarts;
      updatedCartList = updatedCartList.map(cart => {
        if (parseInt(cart.cartId) === parseInt(payload.cartId)) {
          return { ...cart, shipTo: payload.shipTo };
        }
        return cart;
      });

      return { ...state, allCarts: updatedCartList };
    case REMOVE_PRODUCT:
      let cartsList = state.allCarts;
      let currentCartUpdate = state.currentCart;
      cartsList = cartsList.map(cart => {
        if (parseInt(cart.cartId) === parseInt(payload.cartId)) {
          if(cart.TypeId!==4){
            cart.items = cart.items.filter(item => {
              if(cart.TypeId === 201) {
                return !(
                  parseInt(item.number) === parseInt(payload.itemno) &&
                  item.month === payload.month &&
                  parseInt(item.rdcNum) === parseInt(payload.RDC)
                );
              }
              else if(cart.TypeId === 401) {
                return !(
                  parseInt(item.item_nbr) === parseInt(payload.itemno) &&
                  item.model === payload.model &&
                  parseInt(item.rdcNum) === parseInt(payload.RDC)
                );
              }
              else if(cart.TypeId === 501) {
                return !(
                  parseInt(item.item_nbr) === parseInt(payload.itemno) &&
                  item.month === payload.month &&
                  parseInt(item.rdcNum) === parseInt(payload.RDC)
                );
              }
                else if(cart.TypeId === 44) {
                  return !(
                    parseInt(item.model) === parseInt(payload.model) &&
                    parseInt(item.rdcNum) === parseInt(payload.RDC)
                  );
              }
              else if(cart.TypeId === 33) {
                return (
                  parseInt(item.item_nbr) !== parseInt(payload.itemno)
                );
            }
              return !(
                parseInt(item.item_nbr) === parseInt(payload.itemno) &&
                parseInt(item.rdcNum) === parseInt(payload.RDC)
              );
            });
          }else{
            cart.items = cart.items.filter(item=>{
              return parseInt(item.number)!==parseInt(payload.itemno)
            })
          }
          cart.cartCount = cart.items.length;
          cart.totalCost = (cart.TypeId ===2)
          ? parseFloat(
            cart.items.reduce(promoItemReducer,0)
          ).toFixed(2)
          : parseFloat(
            cart.items.reduce(
              (item1, item2) =>
                parseFloat(item1) +
                parseFloat(item2.itemQty) * parseFloat(item2.member_cost),
              0
            )
          ).toFixed(2);
          currentCartUpdate.cartCount =
            parseInt(cart.cartId) === parseInt(currentCartUpdate.cartId)
              ? cart.cartCount
              : currentCartUpdate.cartCount;
        }
        return cart;
      });
      return { ...state, currentCart: currentCartUpdate, allCarts: cartsList };
    case DELETE_CART:
      let updatedCarts = state.allCarts;
      if(payload.parentId !== 0){
        updatedCarts = updatedCarts.filter(
          cart => cart.parentId !== payload.parentId
        );
      }
      else{
        updatedCarts = updatedCarts.filter(
          cart => cart.cartId !== payload.cartId 
        );
      }
      let newCurrCart = state.currentCart;
      if (parseInt(newCurrCart.cartId) === parseInt(payload.cartId)) {
        newCurrCart = updatedCarts.length > 0 ? updatedCarts[0] : {};
      }
      return { ...state, currentCart: newCurrCart, allCarts: updatedCarts };
    case SET_QUANTITY:
      return state;
    case NOTIFY_CART:
      return { ...state, notifyUser: payload.notify };
    case MARKET_ENABLE:
      let marketFlag = payload.marketData;
      marketFlag = marketFlag?.split('#');
      let marketFlagEnabled = marketFlag && marketFlag[0] === 'True' ? true : false;
      let eventDate = marketFlag && marketFlag[1];
      return { ...state, marketFlagEnabled, eventDate }
    case REUNION_ORDERING_ENABLE:
      let configData = payload.reunionOrderingEnable;
      let reunionData = {}
      let retailAssortmentData = {}
      let monthlySizzleSale = {}
      let ctbAssortmentData = {}
      let closeoutCornerData = {}
      let momentumData = {}
      if(Array.isArray(configData)){
        configData.forEach((data)=>{
          if(data.key == 'RA'){
           retailAssortmentData = {...data}
          } else if(data.key == 'REUNION'){
           reunionData = {...data} 
         } else if (data.key == 'MS3'){
          monthlySizzleSale = {...data}
         } else if (data.key == 'CTBRA'){
          ctbAssortmentData = {...data}
         } else if (data.key == 'COC'){   
          closeoutCornerData = {...data}
         }else if (data.key == 'MOMENTUM'){
          momentumData = {...data}
         }
        })
      }
       let startDate = new Date(reunionData.startDate);
       let endDate = new Date(reunionData.endDate);
      //let currentCstTime = new Date(new Date().toLocaleString("en-US", {timeZone: 'UTC'}));      
      //currentCstTime.setHours(currentCstTime.getHours()-6);
      let currentCstTime = new Date(utilities.getCurrentCstTime());
      let isReunionLinkEnable =   currentCstTime >= startDate && currentCstTime <= endDate
      let isRetailAssortmentsEnable = retailAssortmentData?.value == 1 ? true : false
      let isCtbAssortmentsEnable = ctbAssortmentData?.value == 1 ? true : false
      let isCloseoutCornerEnable = closeoutCornerData?.value == 1 ? true : false
      let isMomentumEnable = momentumData?.value == 1 ? true : false
      let saleStartDate = new Date(monthlySizzleSale.startDate);
      let saleEndDate = new Date(monthlySizzleSale.endDate);
      let isMonthlySizzleSaleEnable = currentCstTime >= saleStartDate && currentCstTime <= saleEndDate
      let monthlySizzleSaleValue = isMonthlySizzleSaleEnable ? monthlySizzleSale.value : 1
      return { ...state, isReunionLinkEnable, isRetailAssortmentsEnable, isCtbAssortmentsEnable, isCloseoutCornerEnable, isMomentumEnable, monthlySizzleSaleValue, monthlySizzleSale }
    case PLACE_ORDER:
      let allNewCarts = state.allCarts;
      allNewCarts = allNewCarts.filter(
            cart=> 
            parseInt(cart.cartId)!==parseInt(payload.cart.cartId)
        );
      let currCart = state.currentCart;
      if (parseInt(currCart.cartId) === parseInt(payload.cart.cartId)) {
        currCart = allNewCarts.length > 0 ? allNewCarts[0] : {};
      }
      return { ...state, currentCart:currCart, allCarts: allNewCarts};
    case UPDATE_QTY:
      return {...state,...payload};
    case GETSTATEJSON:
      return {
        ...state, getStateJson: payload
      }
    default:
      return state;
  }
};

const orderHistory = (state = {}, { type, payload }) => {
  switch (type) {
    case PLACE_ORDER:
      return { ...state, [payload.cartName]: payload };
    default:
      return state;
  }
};

export const getCart = state => state.orders;

export default combineReducers({
  orders,
  orderHistory
});
